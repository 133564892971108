import Big from "big.js";
import ERC20_ABI from "constants/abi/erc20.json";
import { useBridgeState } from "../useBridgeState";
import { PROVIDERS_BY_CHAIN } from "pages/Bridge/constants/chains";
import { useWeb3React } from "@web3-react/core";
import { getMulticallContract, multicallFailSafe } from "utils/multicall";
import useSWR from "swr";

export const useBridgeCurrenciesBalanceQuery = (tokenAddresses: Array<string>) => {
  const { account } = useWeb3React();
  const bridgeState = useBridgeState();

  const ReadOnlyProvider = bridgeState.chainFrom ? PROVIDERS_BY_CHAIN[bridgeState.chainFrom] : undefined;

  // ETH has no address
  const tokenAddressesWithoutETH = tokenAddresses.filter((address) => Boolean(address));

  const balancesQuery = useSWR<Record<string, Big>>(
    ["bridge-token-balance", "multicall", account, ...tokenAddresses, bridgeState.chainFrom],
    async () => {
      const calls = tokenAddressesWithoutETH.map((address) => ({
        address,
        name: "balanceOf",
        params: [account],
      }));

      const multicallContract = getMulticallContract(ReadOnlyProvider, bridgeState.chainFrom);

      const balances = await multicallFailSafe(ERC20_ABI, calls, ReadOnlyProvider as any, bridgeState.chainFrom);
      const ethBalance = await multicallContract.getEthBalance(account, { blockTag: "pending" });

      const finalBalances = balances.reduce((acc, balance, index) => {
        const amount = Big(balance[0].toString());

        const tokenAddress = tokenAddressesWithoutETH[index];

        if (tokenAddress) {
          acc[tokenAddress] = amount;
        }

        return acc;
      }, {});

      finalBalances[""] = Big(ethBalance.toString());

      return finalBalances;
    }
    // { isPaused: () => Boolean(account && ReadOnlyProvider && bridgeState.chainFrom && tokenAddresses.length) }

    // {
    //   enabled: Boolean(account && ReadOnlyProvider && bridgeState.chainFrom && tokenAddresses.length),
    // }
  );

  return balancesQuery;
};

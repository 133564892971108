import { CHAIN_LIST } from "pages/Bridge/constants/chains";
import QueryString from "qs";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { ChainId } from "types";
import { useBridgeState } from "./useBridgeState";

export const useValidateBridgeQueryParams = () => {
  const bridgeState = useBridgeState();
  const history = useHistory();

  useEffect(() => {
    const newQuery: Record<string, any> = {
      currency: bridgeState.tokenAddress,
      from: bridgeState.chainFrom,
      to: bridgeState.chainTo,
    };

    if (bridgeState.chainFrom !== ChainId.METIS && bridgeState.chainTo !== ChainId.METIS) {
      newQuery.from = bridgeState.chainFrom;
      newQuery.to = ChainId.METIS;
    }

    const currentChain = CHAIN_LIST.find((chain) => chain.id === newQuery.from);
    const currentToken = (currentChain?.tokens ?? []).find(
      (token) => newQuery.from && token.addresses[newQuery.from] === newQuery.currency
    );

    if (newQuery.from && !currentToken && currentChain) {
      const currentChainTokens = currentChain.tokens.filter(
        (token) => newQuery.to && typeof token.addresses[newQuery.to] !== "undefined"
      );

      const newCurrencyAddress = currentChainTokens[0] ? currentChainTokens[0].addresses[newQuery.from] : undefined;

      if (typeof newCurrencyAddress !== "undefined") {
        newQuery.currency = newCurrencyAddress || undefined;
      }
    }

    const newCurrentToken = (currentChain?.tokens ?? []).find(
      (token) => newQuery.from && token.addresses[newQuery.from] === newQuery.currency
    );

    if (
      newCurrentToken &&
      newQuery.to &&
      newCurrentToken.addresses &&
      typeof newCurrentToken.addresses[newQuery.to] === "undefined"
    ) {
      newQuery.currency = undefined;
    }

    history.replace({
      search: QueryString.stringify({
        currency: newQuery.currency || undefined,
        from: newQuery.from,
        to: newQuery.to,
      }),
    });
  }, [bridgeState.chainFrom, bridgeState.chainTo, bridgeState.tokenAddress]);
};

import { ChainId } from "types";
import { SYNAPSE_BRIDGE } from "../bridges/synapse";
import { BridgeToken } from "./types";

export const OPTIMISM_TOKENS: Array<BridgeToken> = [
  {
    symbol: "ETH",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b4ea7965-b4e1-4120-34c5-e9d21aa8a600/public",
    bridge: SYNAPSE_BRIDGE,
    isMainToken: true,
    addresses: {
      [ChainId.OPTIMISM]: "",
      [ChainId.METIS]: "0x420000000000000000000000000000000000000A",
    },
    decimals: {
      [ChainId.OPTIMISM]: 18,
      [ChainId.METIS]: 18,
    },
  },
  {
    symbol: "USDC",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b89c6d3d-eb81-498b-f480-8f6741c87300/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.OPTIMISM]: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.OPTIMISM]: 6,
      [ChainId.METIS]: 6,
    },
  }
];

import React from "react";

export const AcceptIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 4.98706L6.75 13.2371L3 9.48706"
        stroke="white"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import BridgeForm from "./BridgeForm";

const BridgePageBox = styled.div`
  /* position: relative; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 45px 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      padding: 0 10px 40px;
  `};
`;

export default function Bridge() {
  return (
    <BridgePageBox>
      <Helmet>
        <title>Bridge | Tethys</title>
      </Helmet>

      <BridgeForm />
    </BridgePageBox>
  );
}

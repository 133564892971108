import Big from "big.js";
import { ButtonConfirmed, ButtonPrimary, ConfirmedIcon } from "components/Button";
import { SwapHorizontalIcon } from "icons/SwapHorizontalIcon";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Text } from "rebass";
import { Box } from "components/Box";
import { ArrowRightIcon } from "icons/ArrowRight";
// import { useWalletModalToggle } from "state/application/hooks";
import { LoadingDotsIcon } from "icons/LoadingDotsIcon";
import { CHAIN_LIST, SUPPORTED_BRIDGE_NETWORKS } from "../constants/chains";
import { BridgeToken } from "./BridgeToken";
import { ChainSelect } from "./ChainSelect";
import { useBridgeTokenBalanceQuery } from "./hooks/queries/useBridgeTokenBalanceQuery";
import { setupBridgeNetwork } from "./hooks/setupBridgeNetwork";
import { BridgeApprovalState } from "./hooks/useApproveBridgeToken";
import { useBridgeState } from "./hooks/useBridgeState";
import { useCurrentToken } from "./hooks/useCurrentToken";
import { useManageBridge } from "./hooks/useManageBridge";
import { useValidateBridgeQueryParams } from "./hooks/useValidateBridgeQueryParams";
import {
  BridgeFormBox,
  BridgeFormTitle,
  BridgeFormRow,
  BridgeSwapButton,
  BridgeActions,
  BridgeFormTextBox,
  BridgeFormText,
  BridgeLink,
  BridgeFormHeader,
  SelectedBridgeLink,
  BridgeRightHeader,
  BridgeFormWrapper,
} from "./styles";
import { useMediaWith } from "hooks/useMediaWidth";
import { AcceptIcon } from "icons/AcceptIcon";
// import { useLPorCurrenciesBalanceQuery } from "state/wallet/hooks";
import Tooltip from "components/Tooltip/Tooltip";
import { useWeb3React } from "@web3-react/core";
import BridgeDisclaimerModal from "./DisclaimerModal";

export const BridgeForm = () => {
  const { account, chainId, library } = useWeb3React();
  const history = useHistory();
  const location = useLocation();
  const bridgeState = useBridgeState();
  const [tokenAmount, setTokenAmount] = useState("");
  const [isTransferLoading, setIsTransferLoading] = useState(false);
  const currentToken = useCurrentToken();
  const { upToSmall } = useMediaWith();
  const [isApprovalSubmitted, setIsApprovalSubmitted] = useState(false);
  const [isTransferSuccessful, setIsTransferSuccessful] = useState(false);
  // const updateGlobalBalances = useLPorCurrenciesBalanceQuery();

  const currentTokenBalanceQuery = useBridgeTokenBalanceQuery({
    tokenAddress:
      bridgeState.chainFrom && currentToken?.addresses ? currentToken?.addresses[bridgeState.chainFrom] : undefined,
    decimals:
      bridgeState.chainFrom && currentToken?.decimals ? currentToken.decimals[bridgeState.chainFrom] : undefined,
    chainId: bridgeState.chainFrom,
  });

  const targetTokenBalanceQuery = useBridgeTokenBalanceQuery({
    tokenAddress:
      bridgeState.chainTo && currentToken?.addresses ? currentToken?.addresses[bridgeState.chainTo] : undefined,
    decimals: bridgeState.chainTo && currentToken?.decimals ? currentToken.decimals[bridgeState.chainTo] : undefined,
    chainId: bridgeState.chainTo,
  });

  const queryObject = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as { currency?: string; from?: string; to: string };

  const isValidChain = bridgeState.chainFrom === chainId;
  const isSupportedChain = CHAIN_LIST.some((chain) => chain.id === bridgeState.chainFrom);

  useValidateBridgeQueryParams();

  useEffect(() => {
    if (!isValidChain && isSupportedChain && library) {
      setupBridgeNetwork({
        library,
        account,
        chainId: bridgeState.chainFrom,
      });
    }
  }, [isValidChain, library, account, chainId, bridgeState.chainFrom]);

  const { transfer, approve, approvalState, bridgeEstimate, isBridgeEstimateLoading } = useManageBridge({
    tokenAmount,
  });

  useEffect(() => {
    if (approvalState === BridgeApprovalState.PENDING) {
      setIsApprovalSubmitted(true);
    }
  }, [approvalState]);

  useEffect(() => {
    setIsApprovalSubmitted(false);
  }, [bridgeState.chainFrom, bridgeState.chainTo, bridgeState.tokenAddress, chainId]);

  useEffect(() => {
    setIsTransferSuccessful(false);
  }, [tokenAmount, bridgeState.chainFrom, bridgeState.chainTo, bridgeState.tokenAddress, chainId]);

  const onTransfer = async () => {
    setIsTransferLoading(true);

    try {
      await transfer();

      setIsTransferSuccessful(true);

      await Promise.all([
        currentTokenBalanceQuery.mutate(),
        targetTokenBalanceQuery.mutate(),
        // updateGlobalBalances.refetch(),
      ]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsTransferLoading(false);
    }
  };

  // if (!(queryObject.from && queryObject.to)) {
  //   return <Redirect to={`/bridge?from=${ETHEREUM_CHAIN.id}&to=${ANDROMEDA_CHAIN.id}`} />
  // }

  const currentNetwork = bridgeState.chainFrom ? SUPPORTED_BRIDGE_NETWORKS[bridgeState.chainFrom] : undefined;

  const isCurrentTokenIsAFeeToken =
    currentToken && bridgeState.chainFrom && typeof bridgeEstimate.bridgeFeeTokenAddress !== "undefined"
      ? bridgeEstimate.bridgeFeeTokenAddress === currentToken.addresses[bridgeState.chainFrom]
      : true;

  const bigTokenAmount = Number(tokenAmount) > 0 ? Big(tokenAmount) : Big(0);

  const isAmountFromGreaterThanFee =
    isCurrentTokenIsAFeeToken && bridgeEstimate.bridgeFee ? bigTokenAmount.gt(bridgeEstimate.bridgeFee ?? 0) : true;
  const isAmountFromGreaterThanBalance = bigTokenAmount.gt(currentTokenBalanceQuery.data ?? Big(0));

  const hasError = isAmountFromGreaterThanBalance || !isAmountFromGreaterThanFee || !isValidChain;

  const showApproveFlow =
    !hasError &&
    (approvalState === BridgeApprovalState.NOT_APPROVED ||
      approvalState === BridgeApprovalState.PENDING ||
      (isApprovalSubmitted && approvalState === BridgeApprovalState.APPROVED));

  const getTransferButtonText = () => {
    if (isTransferSuccessful) {
      return `Bridge ${currentToken?.symbol}`;
    }

    if (isAmountFromGreaterThanBalance) {
      return `Insufficient ${currentToken?.symbol} balance`;
    }

    if (!isAmountFromGreaterThanFee) {
      return "Amount should be greater than fee";
    }

    if (bigTokenAmount.eq(0)) {
      return "Enter an amount";
    }

    return `Bridge ${currentToken?.symbol}`;
  };

  const getTransferButton = () => (
    <ButtonPrimary
      disabled={
        !isValidChain ||
        isTransferLoading ||
        !Number(tokenAmount) ||
        !isAmountFromGreaterThanFee ||
        isBridgeEstimateLoading ||
        isAmountFromGreaterThanBalance ||
        approvalState !== BridgeApprovalState.APPROVED ||
        isTransferSuccessful
      }
      onClick={onTransfer}
    >
      {approvalState === BridgeApprovalState.APPROVED || hasError ? (
        <>
          {getTransferButtonText()}
          {isTransferSuccessful ? (
            <Box ml="16px" width="20px" height="20px">
              <AcceptIcon />
            </Box>
          ) : isTransferLoading ? (
            <Box ml="16px" width="20px" height="20px">
              <LoadingDotsIcon color="white" size={20} />
            </Box>
          ) : null}
        </>
      ) : (
        `Bridge ${currentToken?.symbol}`
      )}
    </ButtonPrimary>
  );

  const getApproveButton = () => (
    <ButtonConfirmed
      disabled={
        approvalState === BridgeApprovalState.PENDING ||
        !isValidChain ||
        isAmountFromGreaterThanBalance ||
        !isAmountFromGreaterThanFee ||
        (isApprovalSubmitted && approvalState === BridgeApprovalState.APPROVED)
      }
      onClick={approve}
      confirmed={approvalState === BridgeApprovalState.APPROVED}
    >
      {approvalState === BridgeApprovalState.APPROVED ? (
        <ConfirmedIcon>
          <AcceptIcon />
        </ConfirmedIcon>
      ) : (
        <Box display="flex">
          Approve {currentToken?.symbol}
          {approvalState === BridgeApprovalState.PENDING ? (
            <Box ml="20px" width="16px" height="16px">
              <LoadingDotsIcon color="white" size={20} />
            </Box>
          ) : null}
        </Box>
      )}
    </ButtonConfirmed>
  );

  return (
    <BridgeFormWrapper>
      <BridgeFormBox>
        <BridgeFormHeader>
          <BridgeFormTitle>Bridge to and from Metis</BridgeFormTitle>
          <BridgeRightHeader>
            <SelectedBridgeLink
              style={{ display: "flex", alignItems: "center" }}
              href={currentToken?.bridge.url}
              target="_blank"
              rel="noreferrer"
            >
              <Box
                mr={upToSmall ? "0" : "8px"}
                height="24px"
                width="24px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  key={currentToken?.bridge.logo}
                  style={{ maxWidth: "100%", height: "100%", width: "100%", objectFit: "contain" }}
                  src={currentToken?.bridge.logo}
                />
              </Box>
              {upToSmall ? null : (
                <Text style={{ whiteSpace: "nowrap" }} fontSize="14px">
                  {currentToken?.bridge.name}
                </Text>
              )}
            </SelectedBridgeLink>
            <Tooltip
              // handle={`${formatAmount(allowedSlippage, 2, 2)}%`}
              position="right-top"
              renderContent={() => {
                return `Selected token will be transferred via ${currentToken?.bridge.name}`;
              }}
            />
          </BridgeRightHeader>
        </BridgeFormHeader>

        <BridgeFormRow>
          <ChainSelect
            selectedId={bridgeState.chainFrom}
            onSelect={(id) => {
              if (id === bridgeState.chainTo) {
                history.push({
                  search: qs.stringify({
                    ...queryObject,
                    from: id,
                    to: bridgeState.chainFrom,
                  }),
                });
              } else {
                history.push({
                  search: qs.stringify({
                    ...queryObject,
                    from: id,
                  }),
                });
              }
            }}
            label={"From Chain"}
          />
          <BridgeSwapButton
            onClick={() => {
              const chainToConfig = CHAIN_LIST.find((chain) => chain.id === bridgeState.chainTo);
              const newCurrency = (chainToConfig?.tokens ?? []).find(
                (token) => bridgeState.chainFrom && token.addresses[bridgeState.chainFrom] === bridgeState.tokenAddress
              );

              history.push({
                search: qs.stringify({
                  ...queryObject,
                  currency:
                    newCurrency?.addresses && bridgeState.chainTo
                      ? newCurrency?.addresses[bridgeState.chainTo]
                      : undefined,
                  to: bridgeState.chainFrom,
                  from: bridgeState.chainTo,
                }),
              });
            }}
          >
            <ArrowRightIcon />
            <SwapHorizontalIcon />
          </BridgeSwapButton>
          <ChainSelect
            selectedId={bridgeState.chainTo}
            onSelect={(id) => {
              if (id === bridgeState.chainFrom) {
                history.push({
                  search: qs.stringify({
                    ...queryObject,
                    from: bridgeState.chainTo,
                    to: id,
                  }),
                });
              } else {
                history.push({
                  search: qs.stringify({
                    ...queryObject,

                    to: id,
                  }),
                });
              }
            }}
            label={"To Chain"}
          />
        </BridgeFormRow>
        <BridgeToken
          tokenBalanceQuery={currentTokenBalanceQuery}
          targetTokenBalanceQuery={targetTokenBalanceQuery}
          bridgeFee={bridgeEstimate.bridgeFee}
          bridgeFeeSymbol={bridgeEstimate.bridgeFeeSymbol}
          amountToReceive={bridgeEstimate.amountToReceive}
          amountToReceiveSymbol={bridgeEstimate.amountToReceiveSymbol}
          estimatedTransactionTime={bridgeEstimate.estimatedTransactionTime}
          onTokenSelect={(value) => {
            history.push({
              search: qs.stringify({
                ...queryObject,
                currency: value,
              }),
            });
          }}
          tokenAmount={tokenAmount}
          onTokenAmountInput={(event) => {
            setTokenAmount(event);
          }}
        />
        <BridgeActions>
          {account ? (
            <>
              {isValidChain ? (
                <>
                  {showApproveFlow ? (
                    <Box style={{ gap: "8px" }} display="flex" justifyContent="space-between">
                      {getApproveButton()}
                      {getTransferButton()}
                    </Box>
                  ) : null}

                  {!showApproveFlow ? getTransferButton() : null}
                </>
              ) : (
                <ButtonPrimary
                  onClick={() =>
                    setupBridgeNetwork({
                      library,
                      account,
                      chainId: bridgeState.chainFrom,
                    })
                  }
                >
                  Switch network to {currentNetwork?.chainName}
                </ButtonPrimary>
              )}
            </>
          ) : (
            <ButtonPrimary
            // onClick={toggleWalletModal}
            >
              Connect Wallet
            </ButtonPrimary>
          )}
        </BridgeActions>
      </BridgeFormBox>
      <BridgeFormTextBox>
        <BridgeFormText>
          Bridging could take from a few mins to a few hours. If you encounter any issues while bridging, please contact{" "}
          <BridgeLink href={currentToken?.bridge.url} target="_blank" rel="noreferrer">
            bridge support
          </BridgeLink>
        </BridgeFormText>

        {/* <BridgeFormText>
          2. If after bridging you find yourself in a situation without gas, we offer a{" "}
          <BridgeLink href="https://docs.tethys.finance/products/faucet" target="_blank" rel="noreferrer">
            gas faucet
          </BridgeLink>{" "}
          in our discord
        </BridgeFormText> */}

        {/* <BridgeLink href="" target="_blank" rel="noreferrer">
          Bridge FAQ
        </BridgeLink> */}
      </BridgeFormTextBox>
    </BridgeFormWrapper>
  );
};

export default BridgeForm;

export const METIS_ETHEREUM_ABI = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: '_chainid', type: 'uint256' }],
    name: 'ERC20ChainID',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: '_l1Token', type: 'address' },
      { indexed: true, internalType: 'address', name: '_l2Token', type: 'address' },
      { indexed: true, internalType: 'address', name: '_from', type: 'address' },
      { indexed: false, internalType: 'address', name: '_to', type: 'address' },
      { indexed: false, internalType: 'uint256', name: '_amount', type: 'uint256' },
      { indexed: false, internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'ERC20DepositInitiated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: '_l1Token', type: 'address' },
      { indexed: true, internalType: 'address', name: '_l2Token', type: 'address' },
      { indexed: true, internalType: 'address', name: '_from', type: 'address' },
      { indexed: false, internalType: 'address', name: '_to', type: 'address' },
      { indexed: false, internalType: 'uint256', name: '_amount', type: 'uint256' },
      { indexed: false, internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'ERC20WithdrawalFinalized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: '_from', type: 'address' },
      { indexed: true, internalType: 'address', name: '_to', type: 'address' },
      { indexed: false, internalType: 'uint256', name: '_amount', type: 'uint256' },
      { indexed: false, internalType: 'bytes', name: '_data', type: 'bytes' },
      { indexed: false, internalType: 'uint256', name: 'chainId', type: 'uint256' },
    ],
    name: 'ETHDepositInitiated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: '_from', type: 'address' },
      { indexed: true, internalType: 'address', name: '_to', type: 'address' },
      { indexed: false, internalType: 'uint256', name: '_amount', type: 'uint256' },
      { indexed: false, internalType: 'bytes', name: '_data', type: 'bytes' },
      { indexed: false, internalType: 'uint256', name: 'chainId', type: 'uint256' },
    ],
    name: 'ETHWithdrawalFinalized',
    type: 'event',
  },
  {
    inputs: [],
    name: 'DEFAULT_CHAINID',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'addressmgr',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_l1Token', type: 'address' },
      { internalType: 'address', name: '_l2Token', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
      { internalType: 'uint32', name: '_l2Gas', type: 'uint32' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'depositERC20',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_chainid', type: 'uint256' },
      { internalType: 'address', name: '_l1Token', type: 'address' },
      { internalType: 'address', name: '_l2Token', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
      { internalType: 'uint32', name: '_l2Gas', type: 'uint32' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'depositERC20ByChainId',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_l1Token', type: 'address' },
      { internalType: 'address', name: '_l2Token', type: 'address' },
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
      { internalType: 'uint32', name: '_l2Gas', type: 'uint32' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'depositERC20To',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_chainid', type: 'uint256' },
      { internalType: 'address', name: '_l1Token', type: 'address' },
      { internalType: 'address', name: '_l2Token', type: 'address' },
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
      { internalType: 'uint32', name: '_l2Gas', type: 'uint32' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'depositERC20ToByChainId',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint32', name: '_l2Gas', type: 'uint32' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'depositETH',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_chainId', type: 'uint256' },
      { internalType: 'uint32', name: '_l2Gas', type: 'uint32' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'depositETHByChainId',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint32', name: '_l2Gas', type: 'uint32' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'depositETHTo',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_chainId', type: 'uint256' },
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint32', name: '_l2Gas', type: 'uint32' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'depositETHToByChainId',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'deposits',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'donateETH', outputs: [], stateMutability: 'payable', type: 'function' },
  {
    inputs: [
      { internalType: 'address', name: '_l1Token', type: 'address' },
      { internalType: 'address', name: '_l2Token', type: 'address' },
      { internalType: 'address', name: '_from', type: 'address' },
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'finalizeERC20Withdrawal',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_chainid', type: 'uint256' },
      { internalType: 'address', name: '_l1Token', type: 'address' },
      { internalType: 'address', name: '_l2Token', type: 'address' },
      { internalType: 'address', name: '_from', type: 'address' },
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'finalizeERC20WithdrawalByChainId',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_from', type: 'address' },
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'finalizeETHWithdrawal',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_chainid', type: 'uint256' },
      { internalType: 'address', name: '_from', type: 'address' },
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'finalizeETHWithdrawalByChainId',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_chainid', type: 'uint256' },
      { internalType: 'address', name: '_from', type: 'address' },
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'finalizeMetisWithdrawalByChainId',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_l1messenger', type: 'address' },
      { internalType: 'address', name: '_l2TokenBridge', type: 'address' },
      { internalType: 'address', name: '_metis', type: 'address' },
      { internalType: 'address', name: '_addressmgr', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'l2TokenBridge',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'messenger',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'metis',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
]

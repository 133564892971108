import { BridgeTitle } from 'pages/Bridge/styles'
import styled from 'styled-components'

export const ChainSelectTitle = styled(BridgeTitle)`
  margin: 0 0 32px;
`

export const ChainSelectLogo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  box-shadow: 0px 11px 33px rgba(0, 0, 0, 0.05), 0px 5.56875px 14.3859px rgba(0, 0, 0, 0.03375),
    0px 2.2px 5.3625px rgba(0, 0, 0, 0.025), 0px 0.48125px 1.90781px rgba(0, 0, 0, 0.01625);
  background-color: #ffffff;
  color: rgba(17, 17, 17, 0.3);
`

export const CurrencySelectBox = styled.div`
  margin-top: 36px;
  width: 100%;
`

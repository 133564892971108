import { BridgeTitle } from "pages/Bridge/styles";
import styled from "styled-components";

export const TokenBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  padding: 18px 16px;
  flex-grow: 1;
  width: 100%;
  position: relative;
  z-index: 3;
  border-radius: 8px;
  background: ${({ theme }) => theme.bg2};

  ${({ theme }) => theme.mediaWidth.upToSmall`
      padding: 18px 16px;
  `};
`;

export const TokenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  overflow: hidden;
  z-index: 2;
`;

export const TokenSelectBox = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
      min-width: 77px;
  `};
`;
export const TokenSelectRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;
`;

export const TokenValue = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  flex-grow: 1;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.02em;
`;

export const StyledBalanceMax = styled.button`
  height: 24px;
  border-radius: 22px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  min-width: 50px;
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  box-shadow: none;
  border: none;
  background-color: ${({ theme }) => theme.activeGreyBg};

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.text3};
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    color: ${({ theme }) => (theme.darkMode ? theme.secondaryText3 : "#C4C4C4")};
  }
`;

export const TokenInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  &:nth-child(3) {
    border-top: 1px solid ${({ theme }) => theme.border1};
    padding-top: 20px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`;

export const TokenInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;

  & > * {
    width: calc(50% - 6px);
  }
`;

export const TokenBigRow = styled(TokenInfoRow)`
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      flex-direction: column;
      margin-bottom: 12px;
      align-items: flex-start;

      & > * {
        width: 100%;
      }
  `};
`;

export const TokenSmallRow = styled(TokenInfoRow)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
      & > * {
        width: auto;
      }
  `};
`;

export const TokenName = styled(BridgeTitle)`
  text-align: left;
  color: ${({ theme }) => theme.text1};
`;

export const TokensCount = styled.span`
  display: flex;
  align-items: center;
  width: auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.text1};
`;

export const StyledWarning = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  padding: 14px;
  background: ${({ theme }) => theme.bg2};
`;
export const StyledWarningIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const StyledWarningText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.text1};
`;

import { rgba } from "polished";
import styled from "styled-components";

export const ChainModalList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 -24px;

  @media screen and (max-width: 680px) {
    margin: 0 -16px;
  }
`;

export const ChainModalBtn = styled.button<{ isActive: boolean }>`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: transparent;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.border1};
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  color: ${({ theme }) => theme.text1};
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    border-color: ${({ theme }) => rgba(theme.text3, 0.08)};
    background-color: ${({ theme }) => rgba(theme.text3, 0.08)};
  }

  @media screen and (max-width: 680px) {
    padding: 10px 16px;
  }
`;

export const ChainBtnWrap = styled.span`
  display: grid;
  grid-template-columns: 2fr 1fr 20px;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const ChainLogoBox = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ChainAcceptIcon = styled.span`
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.text1};
`;

export const ChainItemText = styled.span`
  text-align: left;
  color: ${({ theme }) => theme.text1};
`;

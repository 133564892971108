import React, { useState } from "react";
import { BridgeTitle } from "../../styles";
import { CurrencySelect } from "../CurrencySelect";
import { Input as NumericalInput } from "components/NumericalInput";
import WarningIcon from "img/warning-icon.svg";

import {
  StyledBalanceMax,
  StyledWarning,
  StyledWarningIcon,
  StyledWarningText,
  TokenBox,
  TokenInfo,
  TokenInfoRow,
  TokenName,
  TokensCount,
  TokenSelectBox,
  TokenSelectRow,
  TokenSmallRow,
  TokenValue,
  TokenWrapper,
} from "./styles";
import { CHAIN_LIST } from "pages/Bridge/constants/chains";
import ChainModal from "../ChainModal/ChainModal";
import { useBridgeState } from "../hooks/useBridgeState";
import { UseQueryResult } from "react-query";
import Big from "big.js";
import { useBridgeCurrenciesBalanceQuery } from "../hooks/queries/useBridgeCurrenciesBalanceQuery";
import dayjs from "dayjs";
import { formatNumber } from "utils/formatNumber";
import { useMediaWith } from "hooks/useMediaWidth";
import { LoadingDotsIcon } from "icons/LoadingDotsIcon";
import { Box } from "components/Box";
import { responseInterface } from "swr";

type Props = {
  onTokenSelect: (value: string) => void;
  onTokenAmountInput: (value: string) => void;
  tokenAmount: string;
  tokenBalanceQuery: responseInterface<Big | undefined, unknown>;
  targetTokenBalanceQuery: responseInterface<Big | undefined, unknown>;
  bridgeFee: number | undefined;
  bridgeFeeSymbol: string | undefined;
  amountToReceive: Big | undefined;
  amountToReceiveSymbol: string | undefined;
  estimatedTransactionTime: Array<number> | undefined;
};

export const BridgeToken: React.FC<Props> = (props) => {
  const [showTokenModal, setShowTokenModal] = useState(false);
  const bridgeState = useBridgeState();
  const { upToSmall } = useMediaWith();

  const onSetToken = (value: any) => {
    props.onTokenSelect(value.id || undefined);
    setShowTokenModal(false);
  };

  const currentChain = CHAIN_LIST.find((chain) => chain.id === bridgeState.chainFrom);
  const targetChain = CHAIN_LIST.find((chain) => chain.id === bridgeState.chainTo);

  const selectedToken = currentChain?.tokens.find(
    (token) => bridgeState.chainFrom && token.addresses[bridgeState.chainFrom] === bridgeState.tokenAddress
  );

  const tokenList = (currentChain?.tokens ?? []).filter(
    (token) =>
      bridgeState.chainTo &&
      bridgeState.chainFrom &&
      token.addresses &&
      typeof token.addresses[bridgeState.chainTo] !== "undefined" &&
      typeof token.addresses[bridgeState.chainFrom] !== "undefined"
  );

  const bridgeFaucet =
    selectedToken?.bridge.faucetOnTargetChain && bridgeState.chainTo
      ? selectedToken?.bridge.faucetOnTargetChain[bridgeState.chainTo]
      : undefined;

  const bridgeCurrenciesBalanceQuery = useBridgeCurrenciesBalanceQuery(
    tokenList.map((token) => token.addresses[bridgeState.chainFrom as number])
  );

  return (
    <TokenWrapper>
      <TokenBox>
        <BridgeTitle>Token to Bridge</BridgeTitle>

        <TokenSelectRow>
          <TokenSelectBox>
            <CurrencySelect
              logo={selectedToken?.logo}
              name={selectedToken?.symbol}
              label="Select a token"
              onClick={() => setShowTokenModal(true)}
              isTokenSelect
            />
            <ChainModal
              show={showTokenModal}
              toggleShow={() => setShowTokenModal(!showTokenModal)}
              value={bridgeState.tokenAddress}
              setValue={onSetToken}
              label="Select a token"
              values={tokenList.map((token) => {
                const tokenAddress = currentChain?.id ? token.addresses[currentChain?.id] : undefined;

                return {
                  label: token.symbol,
                  logo: token.logo,
                  id: tokenAddress,
                  data: {
                    value:
                      typeof tokenAddress !== "undefined" &&
                      bridgeCurrenciesBalanceQuery.data &&
                      bridgeCurrenciesBalanceQuery.data[tokenAddress] &&
                      currentChain?.id
                        ? formatNumber(
                            Number(
                              bridgeCurrenciesBalanceQuery.data[tokenAddress]
                                .div(10 ** token.decimals[currentChain?.id])
                                .toFixed()
                            ),
                            3
                          )
                        : undefined,
                    isLoading: !bridgeCurrenciesBalanceQuery.data,
                  },
                };
              })}
            />
          </TokenSelectBox>
          <TokenValue>
            <NumericalInput
              style={{
                textAlign: "end",
                minWidth: "44px",
                fontSize: "24px",
              }}
              value={props.tokenAmount}
              onUserInput={(value) => {
                props.onTokenAmountInput(value);
              }}
            />
            <StyledBalanceMax
              disabled={!props.tokenBalanceQuery.data}
              onClick={() => {
                if (props.tokenBalanceQuery.data && bridgeState.chainFrom && selectedToken) {
                  const selectedTokenDecimals = selectedToken.decimals[bridgeState.chainFrom];

                  const minMainTokenAmount = Big(10).pow(selectedTokenDecimals - 2);
                  const maxBalance = props.tokenBalanceQuery.data.mul(10 ** selectedTokenDecimals);

                  const newAmount =
                    maxBalance.gt(minMainTokenAmount) && selectedToken.isMainToken
                      ? maxBalance.minus(minMainTokenAmount)
                      : maxBalance;

                  props.onTokenAmountInput(newAmount.div(10 ** selectedTokenDecimals).toString());
                }
              }}
            >
              Max
            </StyledBalanceMax>
          </TokenValue>
        </TokenSelectRow>
      </TokenBox>
      <TokenInfo>
        <TokenInfoRow>
          <TokenName>Balance on {currentChain?.name}</TokenName>
          <TokensCount title={formatNumber(Number(props.tokenBalanceQuery.data?.toString()), 18)}>
            {selectedToken && props.tokenBalanceQuery.data ? (
              `${formatNumber(Number(props.tokenBalanceQuery.data?.toString()), 4)} ${selectedToken.symbol}`
            ) : !props.tokenBalanceQuery ? (
              <Box width="10px" height="10px">
                <LoadingDotsIcon size={10} />
              </Box>
            ) : (
              "-"
            )}
          </TokensCount>
        </TokenInfoRow>
        <TokenInfoRow>
          <TokenName>Balance on {targetChain?.name}</TokenName>
          <TokensCount title={formatNumber(Number(props.targetTokenBalanceQuery.data?.toString()), 18)}>
            {selectedToken && props.targetTokenBalanceQuery.data ? (
              `${formatNumber(Number(props.targetTokenBalanceQuery.data?.toString()), 4)} ${selectedToken.symbol}`
            ) : !props.targetTokenBalanceQuery ? (
              <Box width="10px" height="10px">
                <LoadingDotsIcon size={10} />
              </Box>
            ) : (
              "-"
            )}
          </TokensCount>
        </TokenInfoRow>
      </TokenInfo>
      {Boolean(props.bridgeFee || props.amountToReceive || bridgeFaucet) ? (
        <TokenInfo>
          {/* <TokenBigRow>
          <TokenName>Current bridgeable range:</TokenName>
          <TokensCount>
            0.2 – 12,000,000 METIS
            <QuestionHelper text={'Text'} />
          </TokensCount>
        </TokenBigRow> */}
          {props.bridgeFee ? (
            <TokenSmallRow>
              <TokenName>Fee:</TokenName>
              <TokensCount>
                {formatNumber(props.bridgeFee, 3)} {props.bridgeFeeSymbol}
              </TokensCount>
            </TokenSmallRow>
          ) : null}
          {props.amountToReceive && Big(props.amountToReceive).gt(0) && selectedToken && targetChain ? (
            <TokenSmallRow>
              <TokenName>Min received:</TokenName>
              <TokensCount>
                {formatNumber(
                  Number(Big(props.amountToReceive).div(10 ** (selectedToken.decimals[targetChain.id] ?? 0))),
                  3
                )}{" "}
                {props.amountToReceiveSymbol}
              </TokensCount>
            </TokenSmallRow>
          ) : null}
          {bridgeFaucet ? (
            <TokenSmallRow>
              <TokenName>You will receive additional:</TokenName>
              <TokensCount>
                {bridgeFaucet.amount} {bridgeFaucet.symbol}
              </TokensCount>
            </TokenSmallRow>
          ) : null}
          {props.estimatedTransactionTime ? (
            <TokenSmallRow>
              <TokenName>Estimated transaction time:</TokenName>
              {props.estimatedTransactionTime.length > 1 ? (
                <TokensCount>
                  {dayjs.duration(props.estimatedTransactionTime[0], "seconds").format("m[m]")}
                  {" - "}
                  {dayjs.duration(props.estimatedTransactionTime[1], "seconds").format("m[m]")}
                </TokensCount>
              ) : (
                <TokensCount>
                  {dayjs.duration(props.estimatedTransactionTime[0], "seconds").format("m[m] s[s]")}
                </TokensCount>
              )}
            </TokenSmallRow>
          ) : null}
          {/* <StyledWarning>
            <StyledWarningIcon src={WarningIcon} alt="Warning!" />
            <StyledWarningText>Amounts greater than X METIS could take up to 12 hours.</StyledWarningText>
          </StyledWarning> */}
        </TokenInfo>
      ) : null}
    </TokenWrapper>
  );
};

import { ExternalProvider } from "@ethersproject/providers";
import { SUPPORTED_BRIDGE_NETWORKS } from "pages/Bridge/constants/chains";
import { ChainId } from "types";

/**
 * Prompt the user to add FTM as a network on Metamask, or switch to FTM if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (externalProvider?: ExternalProvider) => {
  const provider = externalProvider || window.ethereum;

  if (provider) {
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: SUPPORTED_BRIDGE_NETWORKS[ChainId.METIS].chainId }],
      });
      return true;
    } catch (switchError) {
      if ((switchError as any)?.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [SUPPORTED_BRIDGE_NETWORKS[ChainId.METIS]],
          });
          return true;
        } catch (error) {
          console.error("Failed to setup the network in Metamask:", error);
          return false;
        }
      }
      return false;
    }
  } else {
    console.error("Can't setup the Andromeda network on metamask because window.ethereum is undefined");
    return false;
  }
};

import { createClient } from "./utils";
import { SUBGRAPH_URLS } from "config/subgraph";
import { METIS } from "config/chains";

export const chainlinkClient = createClient(SUBGRAPH_URLS.common.chainLink);

export const metisGraphClient = createClient(SUBGRAPH_URLS[METIS].stats);
export const metisReferralsGraphClient = createClient(SUBGRAPH_URLS[METIS].referrals);

export function getGmxGraphClient(chainId: number) {
  if (chainId === METIS) {
    return metisGraphClient;
  } 
  
  throw new Error(`Unsupported chain ${chainId}`);
}

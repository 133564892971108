import { useQuery } from "react-query";
import { request, gql } from "graphql-request";

import Big from "big.js";
import { getAddress } from "@ethersproject/address";
import { getContract } from "config/contracts";
import { useChainId } from "lib/chains";
import useSWR from "swr";

type Response = {
  tokens: Array<{
    id: string;
    totalLiquidity: string;
    derivedETH: string;
    tradeVolumeUSD: string;
    txCount: string;
    totalSupply: string;
    decimals: Number;
  }>;
  tokenDayDatas: Array<{
    totalSupply: string;
    token: {
      id: string;
    };
  }>;
  bundle: {
    ethPrice: string;
  };
  uniswapFactories: {
    totalVolumeUSD: string;
    totalLiquidityUSD: string;
  };
};

export const useTokensInfoQuery = () => {
  const { chainId } = useChainId();

  const tokenAddresses = [getContract(chainId, "GMX")]
    .map((address) => address.toLowerCase())
    .sort((addressA, addressB) => (addressA > addressB ? 1 : -1));

  const fetchData = async () => {
    try {
      const queryString = tokenAddresses.reduce((acc, tokenAddr) => {
        return `
        address${tokenAddr.toLowerCase()}: token(id: "${tokenAddr.toLowerCase()}") {
          derivedETH
        }
        ${acc}
      `;
      }, "");

      const response = await request<Response>(
        "https://graph-node.tethys.finance/subgraphs/name/tethys2",
        gql`
      query tokensInfo($tokensAddress: [ID!], $tokensLength: Int!) {
        ${queryString}

        bundle(id: 1) {
          ethPrice
        }
      }
    `,
        {
          tokensAddress: tokenAddresses,
          tokensLength: tokenAddresses.length,
        }
      );

      const responseObj = {};

      tokenAddresses.forEach((tokenAddr) => {
        const USDPrice = Big(response["address" + tokenAddr].derivedETH).mul(response.bundle.ethPrice);

        responseObj[tokenAddr] = {
          USDPrice: Number(USDPrice),
        };
      });

      return responseObj;
    } catch (error) {
      console.error(error);
    }
  };

  const { data: tokensInfo } = useSWR("get-tokens-info", {
    fetcher: fetchData,
  });

  return tokensInfo;
};

import { useMedia } from "react-use";
import { MEDIA_WIDTHS } from "styles/theme";

export const useMediaWith = () => {
  return {
    upToExtra2Small: useMedia(`(max-width: ${MEDIA_WIDTHS.upToExtra2Small}px)`),
    upToExtraSmall: useMedia(`(max-width: ${MEDIA_WIDTHS.upToExtraSmall}px)`),
    upToSmall: useMedia(`(max-width: ${MEDIA_WIDTHS.upToSmall}px)`),
    upToMedium: useMedia(`(max-width: ${MEDIA_WIDTHS.upToMedium}px)`),
    upToLarge: useMedia(`(max-width: ${MEDIA_WIDTHS.upToLarge}px)`),
    upToExtraLarge: useMedia(`(max-width: ${MEDIA_WIDTHS.upToExtraLarge}px)`),
  };
};

import { t,Trans } from "@lingui/macro";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { BigNumber } from "ethers";
import { formatKeyAmount } from "lib/numbers";

type Props = {
  processedData: {
    gmxAprForEsGmx: BigNumber;
    gmxAprForNativeToken: BigNumber;
    gmxAprForNativeTokenWithBoost: BigNumber;
    gmxBoostAprForNativeToken?: BigNumber;
  };
  nativeTokenSymbol: string;
};

export default function GMXAprTooltip({ processedData, nativeTokenSymbol }: Props) {
  return (
    <>
        <StatsTooltipRow
          label={`${nativeTokenSymbol} APR`}
          showDollar={false}
          value={`${formatKeyAmount(processedData, "gmxAprForNativeToken", 2, 2, true)}%`}
        />
        <StatsTooltipRow
        label={t`Escrowed TETHYS APR`}
        showDollar={false}
        value={`${formatKeyAmount(processedData, "gmxAprForEsGmx", 2, 2, true)}%`}
        />
        
      
      <div>
        <br />
        <Trans>APRs are updated weekly on Friday and will depend on the fees collected for the week.</Trans>
      </div>
    </>
  );
}

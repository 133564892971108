import React, { useMemo } from "react";
import { ThemeProvider as StyledComponentsThemeProvider, css, DefaultTheme } from "styled-components";

type Color = string;

export interface Colors {
  // base
  white: Color;
  black: Color;

  // text
  text1: Color;
  text2: Color;
  text3: Color;
  text4: Color;
  text5: Color;
  text6: Color;
  landingText: Color;

  // backgrounds / greys
  appBg: Color;
  bg1: Color;
  bg2: Color;
  bg3: Color;
  bg4: Color;
  bg5: Color;
  bg6: Color;
  disabledTextColor: Color;
  fixedPanelBg: Color;

  componentBg1: Color;
  componentBg2: Color;
  componentBg3: Color;

  componentBg2Hover: Color;

  inputBg: Color;
  inputShadow: Color;
  buttonBg1: Color;
  buttonBg1Hover: Color;
  buttonBg2: Color;
  buttonBg2Hover: Color;
  buttonBg3: Color;
  buttonBg3Hover: Color;

  modalBG: Color;
  advancedBG: Color;
  grayBG: Color;

  //blues
  primary1: Color;
  primary2: Color;
  primary3: Color;
  primary4: Color;
  primary5: Color;

  primaryText1: Color;
  secondaryText1: Color;
  secondaryText2: Color;
  secondaryText3: Color;
  footerText: Color;
  confirmedText: Color;
  fixedPanelText: Color;

  // other
  red1: Color;
  red2: Color;
  red3: Color;
  green1: Color;
  yellow1: Color;
  yellow2: Color;
  accent: Color;
  accent2: Color;
  accentText: Color;
  accentHover: Color;
  accent2Hover: Color;

  activeTabBg: Color;
  activeGreyBg: Color;

  accent2Border: Color;
  tabsBorder: Color;
  border1: Color;
  border2: Color;
  border2Active: Color;

  link: Color;

  mixedAccentColor: Color;

  borderRadius: string;

  tableRowShadow: string;
  menuBoxShadow: string;

  contrastShadow: string;
  contrastElementShadow: string;

  mobileBackdropBg: string;
  mobileBackdropFilter: string;
  footerBg: string;
  footerLandingBg: string;
  headerLandingBg: string;
  sectionsBoxShadow: string;

  statusOpen: Color;
  statusFilled: Color;
  statusPartFilled: Color;
  statusCanceled: Color;
  scrollbarTrackColor: Color;
  scrollbarThumbColor: Color;

  chartsPreloaderGradient: Color;
  sidebarMenuItemGradient: Color;

  toggleBackground: Color;
}

export const MEDIA_WIDTHS = {
  upToExtra2Small: 320,
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1200,
  upToExtraLarge: 1440,
};

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    (accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `;
    return accumulator;
  },
  {}
) as any;

const white = "#FFFFFF";
const black = "#000000";

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: "#FFFFFF",
    text2: darkMode ? "#727272" : "#565A69",
    text3: "#6F6BE7",
    text4: "rgba(111, 107, 231, 0.48)",
    text5: "#EDEEF2",
    text6: darkMode ? "#727272" : "#000000",
    landingText: darkMode ? "#FFF0CD" : "#0000AE",
    disabledTextColor: darkMode ? "#676767" : "#C4C4C4",
    appBg: darkMode ? "#121212" : "#F1F1F1",
    fixedPanelBg: darkMode ? "rgba(18, 18, 18, 0.7)" : "rgba(241, 241, 241, 0.7)",
    // backgrounds / greys
    bg1: "linear-gradient(176.7deg, rgba(56, 56, 241, 0.16) 2.83%, rgba(56, 56, 241, 0) 279.44%), rgba(40, 40, 71, 0.32)",
    bg2: "linear-gradient(176.7deg, rgba(56, 56, 241, 0.16) 2.83%, rgba(56, 56, 241, 0) 279.44%), rgba(40, 40, 71, 0.32)",
    bg3: "#FFFFFF",
    bg4: "#CED0D9",
    bg5: "#888D9B",
    bg6: darkMode ? "rgba(255, 255, 255, 0.1)" : "#E5E5E5",

    componentBg1: darkMode ? "#282828" : "#FFFFFF",
    componentBg2: darkMode ? "#333333" : "#FFFFFF",
    componentBg3: darkMode ? "#2c2c2c" : "#FFFFFF",

    componentBg2Hover: darkMode ? "#3F3F3F" : "#FFFFFF",

    inputBg: "#FFFFFF",
    inputShadow:
      "0px 38px 94px rgba(0, 0, 0, 0.09), 0px 19.2375px 40.9781px rgba(0, 0, 0, 0.06075), 0px 7.6px 15.275px rgba(0, 0, 0, 0.045), 0px 1.6625px 5.43437px rgba(0, 0, 0, 0.02925)",

    buttonBg1: darkMode ? "#3c3c3c" : "#CED0D9",
    buttonBg1Hover: darkMode ? "#535353" : "#b6b7ba",
    buttonBg2: darkMode ? "#474747" : "#CED0D9",
    buttonBg2Hover: darkMode ? "#595959" : "#b7b8be",
    buttonBg3: "rgba(111, 107, 231, 0.12)",
    buttonBg3Hover: 'linear-gradient(87.2deg, rgba(56, 56, 241, 0) 0%, rgba(56, 56, 241, 0.24) 100%), #28285b',

    //specialty colors
    modalBG: "rgba(0, 0, 0, 0.4)",
    advancedBG: "#F1F1F1",
    grayBG: darkMode ? "#333333" : "#F1F1F1",

    //primary colors
    primary1: "linear-gradient(87.2deg, rgba(255, 240, 205, 0) 0%, rgba(255, 240, 205, 0.3) 100%), #3838F1",
    primary2: darkMode ? "rgba(237, 237, 237, 0.1)" : "rgba(17, 17, 17, 0.1)",
    primary3: "#3838F1",
    primary4: darkMode ? "#3838F1" : "#0000AE",
    primary5: darkMode ? "#6c6c6c" : "#EBEBEB",

    // color text
    primaryText1: darkMode ? "#FFFFFF" : "#000000",
    secondaryText1: "rgba(255, 255, 255, 0.8)",
    secondaryText2: darkMode ? "rgba(255, 255, 255, 0.6)" : "rgba(17, 17, 17, 0.6)",
    secondaryText3: darkMode ? "rgba(255, 255, 255, 0.3)" : "rgba(17, 17, 17, 0.3)",
    footerText: darkMode ? "#FFF0CD" : "#0000AE",
    confirmedText: "#787878",
    fixedPanelText: darkMode ? "#666666" : "#999999",

    // other
    red1: "#E2726B",
    red2: "#F82D3A",
    red3: "#D60000",
    green1: "#81dab1",
    yellow1: "#FFE270",
    yellow2: "#FF9D42",

    accent: "linear-gradient(87.2deg, rgba(255, 240, 205, 0) 0%, rgba(255, 240, 205, 0.3) 100%), #3838F1",
    accent2: "#CCB175",
    accentText: "#FFFFFF",
    accentHover: "#4c4cb9",
    accent2Hover: "#e4ce61",

    link: darkMode ? "#5252ff" : "#0000AE",

    borderRadius: "10px",

    activeTabBg: darkMode ? "#4E4E4E" : "#FFF",
    activeGreyBg: "#363683",

    accent2Border: "#fee3a6",
    tabsBorder: darkMode ? "rgba(233, 233, 233, 0.2)" : "rgba(17, 17, 17, 0.2)",
    border1: "rgba(111, 107, 231, 0.12)",
    border2: darkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(17, 17, 17, 0.1)",
    border2Active: darkMode ? "rgba(255, 255, 255, 0.3)" : "rgba(17, 17, 17, 0.3)",

    mixedAccentColor: darkMode ? "#CCB175" : "#0000AE",

    tableRowShadow: darkMode
      ? "0px 12.16px 14.7936px rgba(0, 0, 0, 0.04384), 0px 2.72px 6.4032px rgba(0, 0, 0, 0.02768)"
      : "0px 10.125px 17.4375px rgba(159, 151, 151, 0.0945), 0px 0.875px 2.3125px rgba(159, 151, 151, 0.0455)",
    menuBoxShadow: darkMode
      ? "0px 10.125px 17.4375px rgba(0, 0, 0, 0.04384), 0px 0.875px 2.3125px rgba(0, 0, 0, 0.02768)"
      : "0px 10.125px 17.4375px rgba(159, 151, 151, 0.0945), 0px 0.875px 2.3125px rgba(159, 151, 151, 0.0455)",

    mobileBackdropBg: "linear-gradient(180deg, rgba(255, 255, 255, 0) 76.53%, #c1c1c1 100%)",
    mobileBackdropFilter:
      "drop-shadow(0px 100px 80px rgba(255, 255, 255, 0.03)), drop-shadow(0px 50.625px 34.875px rgba(255, 255, 255, 0.02025)), drop-shadow(0px 20px 13px rgba(255, 255, 255, 0.015)), drop-shadow(0px 4.375px 4.625px rgba(255, 255, 255, 0.00975))",
    footerBg: darkMode
      ? "linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111111 100%)"
      : "linear-gradient(180deg, rgba(241, 241, 241, 0) 0%, #F1F1F1 100%)",
    footerLandingBg: darkMode
      ? "linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111111 100%)"
      : "linear-gradient(180deg, rgba(241, 241, 241, 0) 0%, #F1F1F1 100%)",
    headerLandingBg: darkMode
      ? "linear-gradient(270deg, rgba(56, 56, 241, 0.2) 0.43%, rgba(56, 56, 241, 0) 87.85%), rgba(29, 29, 29, 0.6)"
      : "linear-gradient(270deg, rgba(56, 56, 241, 0.2) 0.43%, rgba(56, 56, 241, 0) 87.85%), rgba(255, 255, 255, 0.8)",
    statusOpen: "#83B691",
    statusFilled: "#83B691",
    statusPartFilled: "rgba(131, 182, 145, 0.5)",
    statusCanceled: "rgba(17, 17, 17, 0.3)",

    contrastShadow:
      "0px 20px 40px rgba(0, 0, 0, 0.14), 0px 10.125px 17.4375px rgba(0, 0, 0, 0.0945), 0px 4px 6.5px rgba(0, 0, 0, 0.07), 0px 0.875px 2.3125px rgba(0, 0, 0, 0.0455)",
    contrastElementShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

    sectionsBoxShadow: darkMode
      ? "0px 30.24px 33.7824px rgba(0, 0, 0, 0.05616), 0px 12.16px 14.7936px rgba(0, 0, 0, 0.04384), 0px 2.72px 6.4032px rgba(0, 0, 0, 0.02768)"
      : "0px 100px 80px rgba(159, 151, 151, 0.03), 0px 50.625px 34.875px rgba(159, 151, 151, 0.02025), 0px 20px 13px rgba(159, 151, 151, 0.015), 0px 4.375px 4.625px rgba(159, 151, 151, 0.00975)",

    scrollbarTrackColor: darkMode ? "#292929" : "#EBEBEB",
    scrollbarThumbColor: darkMode ? "#493f2a" : "#eae0ca",

    chartsPreloaderGradient:
      "linear-gradient(90deg,rgb(60, 136, 109, 0.23) 0%,rgba(127, 208, 179, 0.16) 30%,rgb(60, 136, 109, 0.23) 75%,rgba(127, 208, 179, 0.16) 100%)",
    sidebarMenuItemGradient: "linear-gradient(88.3deg,#FFFFFF,#0000AE 100%),#2f2f2f",

    toggleBackground: darkMode ? "#363636" : "#CED0D9",
    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  };
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    darkMode,

    //shadows
    shadow1: "#2F80ED",

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  };
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = true;

  const themeObject = useMemo(() => theme(darkMode), [darkMode]);

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>;
}

import axios from "axios";
import Big from "big.js";
import { METIS_ETHEREUM_ABI } from "../../abi/metisBridge/metis-ethereum-abi";
import metisBridgeLogo from "../../../logos/bridges/metis.png";
import { Bridge } from "../types";
import { ChainId } from "types";

export const METIS_BRIDGE: Bridge = {
  logo: metisBridgeLogo,
  url: "https://bridge.metis.io/",
  name: "Metis Bridge",
  contractsInfoByChains: {
    [ChainId.ETHEREUM]: {
      ABI: METIS_ETHEREUM_ABI,
      contract: "0x3980c9ed79d2c191A89E02Fa3529C60eD6e9c04b",
      erc20HandlerAddress: "0x3980c9ed79d2c191A89E02Fa3529C60eD6e9c04b",
      estimate: async ({ amountFrom, chainToTokenDecimals }) => {
        return {
          amountToReceive: Big(amountFrom).mul(10 ** chainToTokenDecimals),
        };
      },
      transfer: async ({
        chainTo,
        chainFromTokenAddress,
        chainToTokenAddress,
        chainFromTokenDecimals,
        isMainToken,
        to,
        amountFrom,
        contract,
      }) => {
        const gasPriceResult = await axios.get("https://api-dac.metis.io/service/api/bridge/gasinmetis");

        const MIN_GAS_PRICE = 200000;

        const gasPrice = gasPriceResult.data.data.l2 < MIN_GAS_PRICE ? MIN_GAS_PRICE : gasPriceResult.data.data.l2;

        const parsedAmountFrom = Big(amountFrom)
          .mul(10 ** chainFromTokenDecimals)
          .toFixed();

        if (isMainToken) {
          const txDeposit = await contract.depositETHToByChainId(`0x${chainTo.toString(16)}`, to, gasPrice, "0x00", {
            value: parsedAmountFrom,
          });

          return txDeposit;
        } else {
          const txDeposit = await contract.depositERC20ToByChainId(
            `0x${chainTo.toString(16)}`,
            chainFromTokenAddress,
            chainToTokenAddress,
            to,
            parsedAmountFrom,
            gasPrice,
            "0x",
            { value: 0 }
          );

          return txDeposit;
        }
      },
    },
  },
};

import React from "react";
import { Trans } from "@lingui/macro";
import "./Buy.css";
import TokenCard from "components/TokenCard/TokenCard";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";

export default function BuyGMXGLP() {
  return (
    <SEO title={getPageTitle("Buy TETHYS or TLP")}>
      <div className="BuyGMXGLP page-layout">
        <div className="BuyGMXGLP-container default-container">
          <div className="section-title-block">
            <div className="section-title-content">
              <div className="Page-title">
                <Trans>Buy TETHYS or TLP</Trans>
              </div>
              <div className="Page-description">
                <Trans>
                  Earn METIS and escrowed TETHYS rewards by staking TETHYS or TLP. <br />
                  For more information, please read the{" "}
                  <ExternalLink href="https://docs.tethys.finance/products/staking">staking program details</ExternalLink>.
                </Trans>
              </div>
            </div>
          </div>
          <TokenCard />
        </div>
      </div>
    </SEO>
  );
}

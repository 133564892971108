import { ChainId } from "types";
import { CELER_BRIDGE } from "../bridges/celer";
import { POLY_BRIDGE } from "../bridges/poly";
import { RELAY_BRIDGE } from "../bridges/relay";
import { SYNAPSE_BRIDGE } from "../bridges/synapse";
import { BridgeToken } from "./types";

export const ANDROMEDA_TOKENS: Array<BridgeToken> = [
  {
    symbol: "USDC",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b89c6d3d-eb81-498b-f480-8f6741c87300/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
      [ChainId.ETHEREUM]: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      [ChainId.BSC]: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      [ChainId.FANTOM]: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
      [ChainId.ARBITRUM]: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
      [ChainId.AVALANCHE]: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
      [ChainId.POLYGON]: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    },
    decimals: {
      [ChainId.METIS]: 6,
      [ChainId.ETHEREUM]: 6,
      [ChainId.BSC]: 18,
      [ChainId.FANTOM]: 6,
      [ChainId.ARBITRUM]: 6,
      [ChainId.AVALANCHE]: 6,
      [ChainId.POLYGON]: 6,
    },
  },
  {
    symbol: "METIS",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/e3a53514-b3ec-4113-eb48-c8a866ee6000/public",
    bridge: CELER_BRIDGE,
    addresses: {
      [ChainId.METIS]: "0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000",
      [ChainId.ETHEREUM]: "0x9E32b13ce7f2E80A01932B42553652E053D6ed8e",
    },
    decimals: {
      [ChainId.METIS]: 18,
      [ChainId.ETHEREUM]: 18,
    },
  },
  {
    symbol: "WETH",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b4ea7965-b4e1-4120-34c5-e9d21aa8a600/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.METIS]: "0x420000000000000000000000000000000000000A",
      [ChainId.AVALANCHE]: "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
      [ChainId.FANTOM]: "0x74b23882a30290451A17c44f4F05243b6b58C76d",
      [ChainId.ETHEREUM]: "",
      [ChainId.ARBITRUM]: "",
      [ChainId.OPTIMISM]: "",
    },
    decimals: {
      [ChainId.METIS]: 18,
      [ChainId.AVALANCHE]: 18,
      [ChainId.FANTOM]: 18,
      [ChainId.ETHEREUM]: 18,
      [ChainId.ARBITRUM]: 18,
      [ChainId.OPTIMISM]: 18,
    },
  },
];

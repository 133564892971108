import React from "react";
import styled, { keyframes } from "styled-components";

const loaderOne = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loaderTwo = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(220deg);
  }
`;

const loaderThree = keyframes`
  0% {
    transform: rotate(-140deg);
  }
  50% {
    transform: rotate(-160deg);
  }
  100% {
    transform: rotate(140deg);
  }
`;

const ItemOne = styled.div`
  animation: ${loaderOne} 4.8s linear infinite;
`;

const ItemTwo = styled.div`
  animation: ${loaderTwo} 1.2s linear infinite;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
`;

const ItemThree = styled.div`
  animation: ${loaderThree} 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 100px;
`;

type Props = {
  size?: number;
  color?: string;
};

export const LoadingDotsIcon: React.FC<Props> = (props) => {
  const color = props.color;
  const size = props.size ?? 48;

  return (
    <ItemOne
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <ItemTwo
        style={{
          clip: `rect(0, ${size}px, ${size}px, ${size / 2}px)`,
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <ItemThree
          style={{
            clip: `rect(0, ${size}px, ${size}px, ${size / 2}px)`,
            width: `${size}px`,
            height: `${size}px`,
            border: `3px solid ${color}`,
          }}
        />
      </ItemTwo>
    </ItemOne>
  );
};

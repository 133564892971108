import React, { useState } from "react";
import { BridgeBox } from "../../styles";
import { CurrencySelect } from "../CurrencySelect";
import { ChainSelectTitle, ChainSelectLogo, CurrencySelectBox } from "./styles";
import ChainModal from "../ChainModal/ChainModal";
import { CHAIN_LIST } from "pages/Bridge/constants/chains";
import { useMediaWith } from "hooks/useMediaWidth";

type Props = {
  selectedId: number | undefined;
  onSelect: (id: number) => void;
  label: string;
};

export const ChainSelect: React.FC<Props> = (props) => {
  const { upToSmall } = useMediaWith();
  const [showChainModal, setShowChainModal] = useState(false);

  const selectedChain = CHAIN_LIST.find((chain) => chain.id === props.selectedId);

  const onSetChain = (value: any) => {
    props.onSelect(value.id);
    setShowChainModal(false);
  };

  return (
    <BridgeBox>
      <ChainSelectTitle>{props.label}</ChainSelectTitle>
      <ChainSelectLogo>
        <img
          alt="logo"
          src={selectedChain?.logo}
          style={{ width: "48px", height: "48px", boxShadow: "none", objectFit: "contain", borderRadius: "50%" }}
        />
      </ChainSelectLogo>
      <CurrencySelectBox>
        <CurrencySelect
          logo={selectedChain?.logo}
          name={selectedChain?.name}
          label="Select a bridge"
          onClick={() => setShowChainModal(true)}
        />
      </CurrencySelectBox>
      <ChainModal
        show={showChainModal}
        toggleShow={() => setShowChainModal(!showChainModal)}
        value={props.selectedId}
        setValue={onSetChain}
        label={props.label}
        values={CHAIN_LIST.map((chain) => ({
          label: chain.name,
          logo: chain.logo,
          id: chain.id,
        }))}
      />
    </BridgeBox>
  );
};

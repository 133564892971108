import styled from 'styled-components'

export const BridgeBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 16px;
  flex-grow: 1;
  background: ${({ theme }) => theme.bg2};
  box-shadow: 0px 38px 94px rgba(0, 0, 0, 0.09), 0px 19.2375px 40.9781px rgba(0, 0, 0, 0.06075),
    0px 7.6px 15.275px rgba(0, 0, 0, 0.045), 0px 1.6625px 5.43437px rgba(0, 0, 0, 0.02925);
  border-radius: 8px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      padding: 12px 16px;
  `};
`

export const BridgeTitle = styled.span`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${({ theme }) => theme.text3};
`
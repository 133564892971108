import { SYNAPSE_ETHEREUM_ABI } from "../../abi/synapseBridge/synapse-ethereum-abi";
import { SYNAPSE_L2_ABI } from "../../abi/synapseBridge/synapse-l2-abi";
import { Bridge as SynapseBridge, Tokens as SynapseTokens } from "@synapseprotocol/sdk";
import synapseBridgeLogo from "../../../logos/bridges/synapse.png";
import { BigNumber } from "ethers";
import Big from "big.js";
import { Bridge } from "../types";
import { Web3Provider } from "@ethersproject/providers";
import { ChainId } from "types";

const synapseEstimate = async ({
  chainFrom,
  chainTo,
  chainFromTokenAddress,
  chainFromTokenDecimals,
  chainToTokenAddress,
  amountFrom,
}) => {
  const synapseTokenFrom = SynapseTokens.AllTokens.find(
    (token) =>
      typeof token.addresses[chainFrom] !== "undefined" &&
      token.addresses[chainFrom]?.toLowerCase() === chainFromTokenAddress.toLowerCase()
  );
  const synapseTokenTo = SynapseTokens.AllTokens.find(
    (token) =>
      typeof token.addresses[chainTo] !== "undefined" &&
      token.addresses[chainTo]?.toLowerCase() === chainToTokenAddress.toLowerCase()
  );

  if (!synapseTokenFrom || !synapseTokenTo) return;

  const synapseBridge = new SynapseBridge.SynapseBridge({ network: chainFrom });

  const estimateResult = await synapseBridge.estimateBridgeTokenOutput({
    chainIdTo: chainTo,
    tokenFrom: synapseTokenFrom,
    tokenTo: synapseTokenTo,
    amountFrom: BigNumber.from(
      Big(amountFrom)
        .mul(10 ** chainFromTokenDecimals)
        .toFixed()
    ),
  });

  return {
    amountToReceive: Big(estimateResult.amountToReceive.toString()),
    amountToReceiveSymbol: synapseTokenTo.symbol,
    bridgeFee: Number(
      Big(estimateResult.bridgeFee.toString())
        .div(10 ** 18 - chainFromTokenDecimals)
        .toFixed()
    ),

    bridgeFeeSymbol: synapseTokenFrom.symbol,
  };
};

const synapseTransfer = async ({
  chainFrom,
  chainTo,
  chainFromTokenAddress,
  chainToTokenAddress,
  chainFromTokenDecimals,
  to,
  amountFrom,
  amountTo,
  library,
}) => {
  const synapseTokenFrom = SynapseTokens.AllTokens.find(
    (token) =>
      typeof token.addresses[chainFrom] !== "undefined" &&
      token.addresses[chainFrom]?.toLowerCase() === chainFromTokenAddress.toLowerCase()
  );
  const synapseTokenTo = SynapseTokens.AllTokens.find(
    (token) =>
      typeof token.addresses[chainTo] !== "undefined" &&
      token.addresses[chainTo]?.toLowerCase() === chainToTokenAddress.toLowerCase()
  );

  if (!(synapseTokenFrom && synapseTokenTo) || !amountTo) return undefined;

  const synapseBridge = new SynapseBridge.SynapseBridge({ network: chainFrom });

  try {
    const txDeposit = await synapseBridge.executeBridgeTokenTransaction(
      {
        tokenFrom: synapseTokenFrom,
        tokenTo: synapseTokenTo,
        amountFrom: BigNumber.from(
          Big(amountFrom)
            .mul(10 ** chainFromTokenDecimals)
            .toFixed()
        ),
        amountTo: BigNumber.from(amountTo.toFixed()),
        chainIdTo: chainTo,
        addressTo: to,
      },
      (library as Web3Provider).getSigner()
    );

    return txDeposit;
  } catch (error) {
    console.error(error);

    return undefined;
  }
};

export const SYNAPSE_BRIDGE: Bridge = {
  logo: synapseBridgeLogo,
  url: "https://synapseprotocol.com/",
  name: "Synapse Bridge",
  faucetOnTargetChain: {
    [ChainId.METIS]: {
      symbol: "METIS",
      amount: 0.02,
    },
  },
  contractsInfoByChains: {
    [ChainId.ETHEREUM]: {
      ABI: SYNAPSE_ETHEREUM_ABI,
      contract: "0x6571d6be3d8460CF5F7d6711Cd9961860029D85F",
      erc20HandlerAddress: "0x6571d6be3d8460CF5F7d6711Cd9961860029D85F",
      estimate: synapseEstimate,
      transfer: synapseTransfer,
    },
    [ChainId.METIS]: {
      ABI: SYNAPSE_L2_ABI,
      contract: "0x6571D58b3BF2469DF5878e213453E28dC1A4DA81",
      erc20HandlerAddress: "0x6571D58b3BF2469DF5878e213453E28dC1A4DA81",
      estimate: synapseEstimate,
      transfer: synapseTransfer,
    },
    [ChainId.BSC]: {
      ABI: SYNAPSE_L2_ABI,
      contract: "0x749F37Df06A99D6A8E065dd065f8cF947ca23697",
      erc20HandlerAddress: "0x749F37Df06A99D6A8E065dd065f8cF947ca23697",
      estimate: synapseEstimate,
      transfer: synapseTransfer,
    },
    [ChainId.FANTOM]: {
      ABI: SYNAPSE_L2_ABI,
      contract: "0xB003e75f7E0B5365e814302192E99b4EE08c0DEd",
      erc20HandlerAddress: "0xB003e75f7E0B5365e814302192E99b4EE08c0DEd",
      estimate: synapseEstimate,
      transfer: synapseTransfer,
    },
    [ChainId.AVALANCHE]: {
      ABI: SYNAPSE_L2_ABI,
      contract: "0x0EF812f4c68DC84c22A4821EF30ba2ffAB9C2f3A",
      erc20HandlerAddress: "0x0EF812f4c68DC84c22A4821EF30ba2ffAB9C2f3A",
      estimate: synapseEstimate,
      transfer: synapseTransfer,
    },
    [ChainId.ARBITRUM]: {
      ABI: SYNAPSE_L2_ABI,
      contract: "0x37f9aE2e0Ea6742b9CAD5AbCfB6bBC3475b3862B",
      erc20HandlerAddress: "0x37f9aE2e0Ea6742b9CAD5AbCfB6bBC3475b3862B",
      estimate: synapseEstimate,
      transfer: synapseTransfer,
    },
    [ChainId.OPTIMISM]: {
      ABI: SYNAPSE_L2_ABI,
      contract: "0x9CD619c50562a38edBdC3451ade7B58CaA71Ab32",
      erc20HandlerAddress: "0x9CD619c50562a38edBdC3451ade7B58CaA71Ab32",
      estimate: synapseEstimate,
      transfer: synapseTransfer,
    },
    [ChainId.POLYGON]: {
      ABI: SYNAPSE_L2_ABI,
      contract: "0x1c6aE197fF4BF7BA96c66C5FD64Cb22450aF9cC8",
      erc20HandlerAddress: "0x1c6aE197fF4BF7BA96c66C5FD64Cb22450aF9cC8",
      estimate: synapseEstimate,
      transfer: synapseTransfer,
    },
  },
};

import { useEffect, useState } from "react";
import { FALLBACK_PROVIDERS, getFallbackRpcUrl, getRpcUrl } from "config/chains";
import { ethers } from "ethers";
import { StaticJsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { ChainId } from "types";

export function getProvider(library: Web3Provider | undefined, chainId: number) {
  // if (library) {
  //   library._pollingInterval = 500;

  //   return library.getSigner();
  // }

  const prov = new StaticJsonRpcProvider(
    getRpcUrl(chainId),
    // @ts-ignore incorrect Network param types
    chainId
  );

  prov.pollingInterval = 500;
  prov._pollingInterval = 500;

  return prov;
}

export function getFallbackProvider(chainId: number) {
  if (!FALLBACK_PROVIDERS[chainId]) {
    return;
  }

  const provider = getFallbackRpcUrl(chainId);

  return new StaticJsonRpcProvider(
    provider,
    // @ts-ignore incorrect Network param types
    chainId
  );
}

export function useJsonRpcProvider(chainId: number) {
  const [provider, setProvider] = useState<StaticJsonRpcProvider>();

  useEffect(() => {
    async function initializeProvider() {
      const rpcUrl = getRpcUrl(chainId);

      if (!rpcUrl) return;

      const provider = new ethers.providers.StaticJsonRpcProvider(rpcUrl);

      provider.pollingInterval = 500;
      provider._pollingInterval = 500;

      await provider.ready;

      setProvider(provider);
    }

    initializeProvider();
  }, [chainId]);

  return { provider };
}

import { ChainId } from "types";
import { SYNAPSE_BRIDGE } from "../bridges/synapse";
import { BridgeToken } from "./types";

export const POLYGON_TOKENS: Array<BridgeToken> = [
  {
    symbol: "USDC",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b89c6d3d-eb81-498b-f480-8f6741c87300/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.POLYGON]: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.POLYGON]: 6,
      [ChainId.METIS]: 6,
    },
  },
  {
    symbol: "USDT",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/52913bcf-9597-4661-d045-102aff10e600/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.POLYGON]: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.POLYGON]: 6,
      [ChainId.METIS]: 6,
    },
  },
  {
    symbol: "DAI",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/0775bbfc-3d9e-4d9e-cecf-ef790b9e7400/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.POLYGON]: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.POLYGON]: 18,
      [ChainId.METIS]: 6,
    },
  },
];

import React from 'react'

export const ArrowRightIcon: React.FC = (props) => {
  return (
    <svg
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="1.5"
    >
      <path d="M16 17l5-5-5-5" strokeLinecap="round"></path>
      <path d="M3 12h18" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
  )
}

import { ethers } from "ethers";
import { getContract } from "./contracts";
import { METIS } from "./chains";
import { Token } from "domain/tokens";

export const TOKENS: { [chainId: number]: Token[] } = {
  [METIS]: [
    {
      name: "Metis",
      symbol: "METIS",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets-global.website-files.com/6507242ad3e4e6ff563301e4/65456c8e4f6aaae6afc5eb7d_Symbol.svg",
    },
    {
      name: "Wrapped Metis",
      symbol: "WMETIS",
      decimals: 18,
      address: "0x71802e8F394BB9d05a1b8E9d0562917609FD7325",
      isWrapped: true,
      baseSymbol: "METIS",
      imageUrl: "https://assets-global.website-files.com/6507242ad3e4e6ff563301e4/65456c8e4f6aaae6afc5eb7d_Symbol.svg",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0x420000000000000000000000000000000000000A",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      address: "0x433E43047B95cB83517abd7c9978Bdf7005E9938",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "AAVE",
      symbol: "AAVE",
      address: "0xd1F0A4E5444EEd0fbcd6624DCef7Ef33043E6168",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12645/small/AAVE.png?1601374110",
    },
  ]
};

export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {
  [METIS]: [
    {
      name: "TETHYS",
      symbol: "TETHYS",
      address: getContract(METIS, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/22141/small/wjoZJvzA_400x400.jpg?1640927753",
    },
    {
      name: "TETHYS LP",
      symbol: "TLP",
      address: getContract(METIS, "GLP"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/22141/small/wjoZJvzA_400x400.jpg?1640927753",
    },
  ],
};

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {
  [METIS]: {
    // avalanche
    GMX: {
      name: "TETHYS",
      symbol: "TETHYS",
      decimals: 18,
      address: getContract(METIS, "GMX"),
      imageUrl: "https://assets.coingecko.com/coins/images/22141/small/wjoZJvzA_400x400.jpg?1640927753",
    },
    GLP: {
      name: "TETHYS LP",
      symbol: "TLP",
      decimals: 18,
      address: getContract(METIS, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "https://assets.coingecko.com/coins/images/22141/small/wjoZJvzA_400x400.jpg?1640927753",
    },
  },
};

export const ICONLINKS = {
  [METIS]: {
    METIS: {
      coingecko: "https://www.coingecko.com/en/coins/metis-token",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      metis: "https://ftmscan.com/address/0x74b23882a30290451A17c44f4F05243b6b58C76d",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/bitcoin",
      metis: "https://ftmscan.com/address/0x321162Cd933E2Be498Cd2267a90534A804051b11",
    },
    AAVE: {
      coingecko: "https://www.coingecko.com/en/coins/aave",
      metis: "https://ftmscan.com/address/0x321162Cd933E2Be498Cd2267a90534A804051b11",
    },
    WMETIS: {
      coingecko: "https://www.coingecko.com/en/coins/metis-token",
      metis: "https://ftmscan.com/address/0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      metis: "https://ftmscan.com/address/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
    },
  }
};

export const GLP_POOL_COLORS = {
  ETH: "#6062a6",
  BTC: "#f2a900",
  USDC: "#2775ca",
  METIS: "#00DACC",
  AAVE: "#B6509E",
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};

const CHAIN_IDS = [METIS];

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDG");
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}

import clsx from "clsx";
import Portal from "components/Common/Portal";
import Modal from "components/Modal/Modal";
import { AcceptTwoIcon } from "icons/AcceptTwoIcon";
import { LoadingDotsIcon } from "icons/LoadingDotsIcon";
import React from "react";
import { CurrencyLogoBoxModal } from "../CurrencySelect/styles";
import { ChainAcceptIcon, ChainBtnWrap, ChainItemText, ChainLogoBox, ChainModalBtn, ChainModalList } from "./styles";
import "./styles.css";
export interface ChainModalProps {
  show: boolean;
  toggleShow(s: boolean): void;
  value: any;
  setValue: (value: any) => void;
  values: Array<any>;
  label: string;
}

export default function ChainModal(props: ChainModalProps): JSX.Element {
  return (
    <Portal>
      <Modal className="ChainModal" isVisible={props.show} setIsVisible={props.toggleShow} label={props.label}>
        <ChainModalList>
          {/* <span className="border-solid border-t-0 border-l-0 border-r-0 border-b border-modalBorder dark:border-modalBorderDark"></span> */}
          {props.values.map((value) => {
            return (
              <ChainModalBtn
                key={value.label}
                onClick={() => value !== props.value && props.setValue(value)}
                isActive={props.value === value.id}
              >
                <ChainBtnWrap>
                  <ChainLogoBox>
                    <CurrencyLogoBoxModal>
                      <img
                        style={{ width: "24px", height: "24px", objectFit: "contain", borderRadius: '50%' }}
                        src={value.logo}
                        alt="logo"
                      />
                    </CurrencyLogoBoxModal>
                    {value.label}
                  </ChainLogoBox>

                  {value.data ? (
                    <ChainItemText>
                      {value.data.isLoading ? <LoadingDotsIcon color="white" size={16} /> : value.data.value}
                    </ChainItemText>
                  ) : (
                    <span></span>
                  )}
                  <ChainAcceptIcon>{props.value === value.id && <AcceptTwoIcon />}</ChainAcceptIcon>
                </ChainBtnWrap>
              </ChainModalBtn>
            );
          })}
        </ChainModalList>
      </Modal>
    </Portal>
  );
}

import { JsonRpcSigner, StaticJsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { ADDRESS_ZERO } from "@uniswap/v3-sdk";
import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import { isAddress } from "ethers/lib/utils";
import { useMemo } from "react";

import ERC20Abi from "constants/abi/erc20.json";

export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? library.getSigner(account).connectUnchecked() : library;
}

export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === ADDRESS_ZERO) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20Abi, withSignerIfPossible);
}

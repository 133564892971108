import { CHAIN_LIST } from 'pages/Bridge/constants/chains'
import { BridgeToken } from 'pages/Bridge/constants/tokens/types'
import { useBridgeState } from './useBridgeState'

export const useCurrentToken = (): BridgeToken | undefined => {
  const bridgeState = useBridgeState()

  const currentChain = CHAIN_LIST.find((chain) => chain.id === bridgeState.chainFrom)

  const selectedToken = (currentChain?.tokens ?? []).find(
    (token) => bridgeState.chainFrom && token.addresses[bridgeState.chainFrom] === bridgeState.tokenAddress
  )

  return selectedToken
}

import React, { useRef } from "react";
import styled, { keyframes } from "styled-components";
import { darken } from "polished";

import { Button as RebassButton, ButtonProps } from "rebass/styled-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";

export const Base = styled(RebassButton)<{
  padding?: string;
  width?: string;
  borderRadius?: string;
  altDisabledStyle?: boolean;
}>`
  padding: ${({ padding }) => (padding ? padding : "12px")};
  width: ${({ width }) => (width ? width : "100%")};
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  border-radius: 8px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;
  border: 1px solid transparent;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
    opacity: 0.4;
  }

  > * {
    user-select: none;
  }
`;

export const ButtonModal = styled(Base)<{ size?: "large" | "medium" | "small" }>`
  background: ${({ theme }) => (theme.darkMode ? "#1E1E1E" : "#FFFFFF")};
  color: ${({ theme }) => theme.primaryText1};
  border-radius: 30px;
  transition: all 0.3s;
  box-shadow: 0px 4px 40px rgb(0 0 0 / 5%), 0px 2px 17.4375px rgb(0 0 0 / 10%), 0px 4px 6.5px rgb(0 0 0 / 7%),
    0px 0.875px 2.3125px rgb(0 0 0 / 5%);

  padding: ${({ size }) => {
    if (size === "small") {
      return "0 20px;";
    }

    if (size === "medium") {
      return "0 40px;";
    }

    return "0 60px;";
  }};
  height: ${({ size }) => {
    if (size === "small") {
      return "24px;";
    }
    if (size === "medium") {
      return "36px;";
    }

    return "48px;";
  }};
  border: none;

  &:hover {
    box-shadow: 0px 20px 40px rgb(0 0 0 / 14%), 0px 10.125px 17.4375px rgb(0 0 0 / 9%), 0px 4px 6.5px rgb(0 0 0 / 7%),
      0px 0.875px 2.3125px rgb(0 0 0 / 5%);
  }
`;

export const ButtonPrimary = styled(Base)<{ loading?: boolean }>`
  position: relative;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  background: ${({ theme }) => theme.accent};
  color: ${({ theme }) => theme.accentText};
  pointer-events: ${({ loading }) => (loading ? "none" : "all")};
  transition: all 0.3s;
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%);
    width: 100%;
    height: 100%;
    background: var(--btn-hover);
    border-radius: 0.4rem;
    opacity: 0;
    content: "";
    z-index: -1;
    transition: opacity 0.3s, transform 0.6s;
  }
  &:hover:after {
    opacity: 1;
    transform: translate(0);
  }

  &:disabled {
    cursor: auto;
    outline: none;
    pointer-events: none;
    opacity: 0.4;
  }
`;

export const ButtonPrimaryNormal = styled(Base)`
  background-color: ${({ theme }) => theme.accent};
  color: ${({ theme }) => theme.accentText};
  padding: 11px 30px;
  transition: all 0.3s;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.accent)};
    background-color: ${({ theme }) => darken(0.05, theme.accent)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.accent)};
    color: #ffffff;
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.accent)};
    background-color: ${({ theme }) => darken(0.1, theme.accent)};
  }
  &:disabled {
    background-color: ${({ theme, altDisabledStyle, disabled }) =>
      altDisabledStyle ? (disabled ? theme.primary2 : theme.accent) : theme.primary2};
    color: ${({ theme, altDisabledStyle, disabled }) =>
      altDisabledStyle ? (disabled ? theme.text3 : "white") : theme.text3};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? "0.5" : "1")};
  }
`;

export const ButtonLight = styled(Base)`
  background-color: ${({ theme }) => theme.primary5};
  color: ${({ theme }) => theme.primaryText1};
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.primary5)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.primary5)};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.primary5};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`;

export const ButtonGray = styled(Base)`
  background-color: ${({ theme }) => theme.buttonBg3};
  color: ${({ theme }) => theme.text1};
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  &:focus {
    background: ${({ theme }) => theme.buttonBg3Hover};
  }
  &:hover {
    background: ${({ theme }) => theme.buttonBg3Hover};
  }
  &:active {
    background: ${({ theme }) => theme.buttonBg3Hover};
  }
`;

export const ButtonRemoveGrey = styled(Base)`
  background-color: ${({ theme }) => theme.primary2};
  padding: 11px 30px;
  color: ${({ theme }) => theme.primaryText1};
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.2, theme.primary2)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.2, theme.primary2)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.primary2)};
  }
`;

export const ButtonSecondary = styled(Base)`
  border: 1px solid ${({ theme }) => theme.mixedAccentColor};
  color: ${({ theme }) => theme.mixedAccentColor};
  background-color: transparent;
  font-size: 14px;
  border-radius: 30px;
  padding: ${({ padding }) => (padding ? padding : "10px")};
  transition: all 0.3s;
  &:focus {
    opacity: 0.8;
  }
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`;

export const ButtonPink = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: white;
  transition: all 0.3s;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary1};
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonUNIGradient = styled(ButtonPrimary)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.primary2};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #0094ec 100%), #edeef2;
  width: fit-content;
  position: relative;
  cursor: pointer;
  border: none;
  white-space: no-wrap;
  transition: all 0.3s;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.9;
  }
`;

export const ButtonPoolTokenStyled = styled(Base)`
  border: none;
  background-color: ${({ theme }) => theme.componentBg2};
  color: ${({ theme }) => theme.text1};
  border-radius: 20px;
  box-shadow: ${({ theme }) => theme.inputShadow};

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.mixedAccentColor};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonWhite = styled(Base)`
  border: 1px solid #edeef2;
  background-color: ${({ theme }) => theme.bg1};
  color: black;
  transition: all 0.3s;
  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    box-shadow: 0 0 0 1pt ${darken(0.05, "#edeef2")};
  }
  &:hover {
    box-shadow: 0 0 0 1pt ${darken(0.1, "#edeef2")};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, "#edeef2")};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;
export const ConfirmedIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.confirmedText};
  transition: opacity 0.5s ease;
  z-index: 2;
`;

const ButtonConfirmedStyle = styled(Base)<{ loading?: boolean }>`
  padding: 0;
  background-color: ${({ theme, loading }) => (loading ? theme.bg1 : theme.primary3)};
  color: ${({ theme }) => theme.confirmedText};
  border: 1px solid transparent;
  font-weight: 600;
  font-size: 14px;
  width: 44px;
  height: 44px;
  transition: width 0.9s ease;
  flex-shrink: 0;

  &:disabled {
    opacity: 1;
    cursor: auto;
  }
`;

const ButtonErrorStyle = styled(Base)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.red1)};
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.red1)};
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
  &:disabled {
    opacity: 0.4;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.red1};
    border: 1px solid ${({ theme }) => theme.red1};
  }
`;

export const ButtonGold = styled(Base)`
  background-color: ${({ theme }) => theme.accent2};
  color: ${({ theme }) => theme.black};
  font-weight: 400;
  font-size: 14px;
  transition: all 0.3s;
  padding: 8px 14px;
`;

export const ButtonDisabled = styled(Base)`
  background-color: ${({ theme }) => theme.buttonBg3};
  color: ${({ theme }) => theme.disabledTextColor};
  font-weight: 400;
  font-size: 14px;
  transition: all 0.3s;
  padding: 8px 14px;
`;

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean; loading?: boolean } & ButtonProps) {
  const confirmedRef = useRef(null);
  const initialRef = useRef(null);
  const nodeRef = confirmed ? confirmedRef : initialRef;
  return (
    <SwitchTransition>
      <CSSTransition ref={nodeRef} addEndListener={() => confirmed} key={confirmed} classNames="confirmed-button">
        {confirmed ? (
          <ButtonConfirmedStyle {...rest} disabled />
        ) : (
          <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
        )}
      </CSSTransition>
    </SwitchTransition>
  );
}

export function ButtonError({
  error,
  altDisabledStyle,
  ...rest
}: { error?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />;
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />;
  }
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...rest} />;
  } else {
    return <ButtonPrimary {...rest} />;
  }
}

import styled from 'styled-components'


export const BridgeFormWrapper = styled.div`
  width: 100%;
  max-width: 455px;
  display: flex;
  flex-direction: column;
`

export const BridgeFormBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  flex-grow: 1;
  flex-shrink: 0;
  background: ${({ theme }) => theme.bg1};
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 50.625px 34.875px rgba(0, 0, 0, 0.02025),
    0px 20px 13px rgba(0, 0, 0, 0.015), 0px 4.375px 4.625px rgba(0, 0, 0, 0.00975);
  border-radius: 8px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      padding: 25px 16px;
  `};
`

export const BridgeFormHeader = styled.div`
  margin: 0 0 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      margin: 0 0 24px;
  `};
`

export const BridgeRightHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const BridgeFormTitle = styled.h1`
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin: 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    line-height: 26px;
  `};
`

export const BridgeFormTextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
  padding: 0 24px;
  margin-top: 32px;
  color: ${({ theme }) => theme.text4};

  ${({ theme }) => theme.mediaWidth.upToSmall`
      margin-top: 30px;
  `};
`

export const BridgeFormText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
`

export const BridgeLink = styled.a`
  margin-top: 24px;

  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
`

export const BridgeFormRow = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;

  & > div {
    width: calc(50% - 10px);
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
      gap: 10px;
  `};
`

export const BridgeActions = styled.div`
  margin-top: 24px;
`

export const BridgeSwapButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
  background: ${({ theme }) => theme.primary1};
  color: #ffffff;
  cursor: pointer;
  transition: box-shadow 0.4s;
  box-shadow: 0px 0.875px 7.97813px rgba(0, 0, 0, 0.0325), 0px 4px 22.425px rgba(0, 0, 0, 0.05),
    0px 10.125px 60.1594px rgba(0, 0, 0, 0.0675), 0px 20px 138px rgba(0, 0, 0, 0.1);

  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 28px;
      height: 28px;
  `};

  &:hover {
    box-shadow: ${({ theme }) => (theme.darkMode ? '0px 4px 28px rgb(0 0 0 / 70%)' : '0px 4px 28px rgb(0 0 0 / 50%)')};
  }

  &:hover svg:first-of-type {
    opacity: 0;
  }

  &:hover svg:last-of-type {
    opacity: 1;
  }

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 20px;
    height: 20px;
    transition: opacity 0.5s ease;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 16px;
      height: 16px;
      display: none;
  `};
  }

  & > svg:last-of-type {
    opacity: 0;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      opacity: 1;
      display: block;
    `};
  }
`

export const SelectedBridgeLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.primary1};
`

import React from "react";
import cx from "classnames";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import { SOCIAL_LINKS } from "./constants";
import dayjs from "dayjs";
import AuditedByLogo from "img/AuditProviderLogo.svg";

type Props = { showRedirectModal?: (to: string) => void; redirectPopupTimestamp?: () => void };

export function Footer({ showRedirectModal, redirectPopupTimestamp }: Props) {
  return (
    <div className="Footer-Wrapper">
      <div className="Footer-Wrapper-Inner">
        <div className="Footer-Links-Wrapper">
          <div className="Footer-Links-Column">
            <span className="Footer-Links-Label">Tethys Token</span>
            <a
              className="Footer-Link"
              href="https://www.coingecko.com/en/coins/tethys-finance"
              target="_blank"
              rel="noreferrer"
            >
              Coingecko
            </a>
            <a
              className="Footer-Link"
              href="https://coinmarketcap.com/currencies/tethys/"
              target="_blank"
              rel="noreferrer"
            >
              CoinMarketCap
            </a>
            <a
              className="Footer-Link"
              href="https://dexscreener.com/metis/0xc9b290ff37fa53272e9d71a0b13a444010af4497"
              target="_blank"
              rel="noreferrer"
            >
              Dexscreener
            </a>
          </div>
          <div className="Footer-Links-Column">
            <span className="Footer-Links-Label">Tethys DEX</span>
            <a
              className="Footer-Link"
              href="https://www.coingecko.com/en/exchanges/tethys"
              target="_blank"
              rel="noreferrer"
            >
              Coingecko
            </a>
            <a
              className="Footer-Link"
              href="https://defillama.com/protocol/tethys-finance"
              target="_blank"
              rel="noreferrer"
            >
              Defi Llama
            </a>
            <a className="Footer-Link" href="https://dexscreener.com/metis/tethys" target="_blank" rel="noreferrer">
              Dexscreener
            </a>
            <a
              className="Footer-Link"
              href="https://coinmarketcap.com/exchanges/tethys"
              target="_blank"
              rel="noreferrer"
            >
              CoinMarketCap
            </a>
          </div>
          <div className="Footer-Links-Column">
            <span className="Footer-Links-Label">Bridging</span>
            <a
              className="Footer-Link"
              href="https://docs.tethys.finance/products/faucet"
              target="_blank"
              rel="noreferrer"
            >
              Faucet
            </a>
            <a className="Footer-Link" href="https://bridge.metis.io/" target="_blank" rel="noreferrer">
              Metis
            </a>
            <a className="Footer-Link" href="https://oportal.boringdao.com/twoway" target="_blank" rel="noreferrer">
              BoringDAO
            </a>
            <a className="Footer-Link" href="https://synapseprotocol.com/" target="_blank" rel="noreferrer">
              Synapse
            </a>
            <a className="Footer-Link" href="https://stargate.finance/transfer" target="_blank" rel="noreferrer">
              Stargate
            </a>
          </div>
          <div className="Footer-Links-Column">
            <span className="Footer-Links-Label">Business</span>
            <a className="Footer-Link" href="https://gov.tethys.finance" target="_blank" rel="noreferrer">
              Governance
            </a>
            <a className="Footer-Link" href="https://docs.tethys.finance/" target="_blank" rel="noreferrer">
              Docs
            </a>
          </div>
        </div>
        <div className="Footer-Socials-Wrapper">
          {SOCIAL_LINKS.map((platform) => {
            return (
              <a
                className="Footer-Social-Item"
                key={platform.name}
                href={platform.link}
                target="_blank"
                rel="noreferrer"
              >
                <img src={platform.icon} alt={platform.name} />
              </a>
            );
          })}
        </div>
        <div className="Copyright-Row">
          <div className="Footer-Copyright-Text">© {dayjs().format("YYYY")} Tethys Labs</div>
          <a
            className="Footer-Audit-Wrapper"
            href="https://defisecurity.io/dist/files/tethys_finance_security_audit_report_1.0.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <span className="Footer-Audit-Text">Audited by</span>
            <img src={AuditedByLogo} className="Footer-Audit-Icon" alt="Audited By" />
          </a>
        </div>
      </div>
    </div>
  );
}

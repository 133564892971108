import { ethers } from "ethers";
import { METIS } from "./chains";

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const CONTRACTS = {
  
  [METIS]: {
    // METIS
    Vault: "0xD2032462fd8A45C4BE8F5b90DE25eE3631ec1c2C",
    Router: "0x3931DB2254808F220e81e8e45666F709BA7BDebF",
    VaultReader: "0x07284EB3bdfE2a3cB33cC93fFADEE86ea0fbA8Ea",
    Reader: "0x27F9De35c29A78D42022d05E9445F86C00896Ef3",
    GlpManager: "0x802a8b00876Cd1B8aC1Df80916012BC31cfa049C",
    RewardRouter: "0xF69bC64c3e265034DCb4A5cB1503992705B10801",
    GlpRewardRouter: "0xF69bC64c3e265034DCb4A5cB1503992705B10801",
    RewardReader: "0xeA1c2545f3A8c74841238b422Ef60b3b8Ec05ed2",
    NATIVE_TOKEN: "0x71802e8F394BB9d05a1b8E9d0562917609FD7325", //WMETIS
    GLP: "0xc2CB519729Ef467D8c0f54c1d4789FdE08ef123E",
    GMX: "0x69fdb77064ec5c84FA2F21072973eB28441F43F3",
    ES_GMX: "0x8cF5A1Aa4553eB40791400928a2b49787B252359",
    BN_GMX: "0xfd2F0A0bFd4e46050D174C6c9A60274CE4a77561",
    USDG: "0x957DBad47E0eD543B4cC6a7443260973e4bF767f",
    ES_GMX_IOU: "", // placeholder address

    StakedGmxTracker: "0xA3c1694EfCd4389Ce652D521d2be28c912250a53",
    BonusGmxTracker: "0xf1466C6c68c7C0526015424E17a2c42769B3fe83",
    FeeGmxTracker: "0xE7cC1FeE584a51e71E2f9D5547c8912318429A39",
    StakedGlpTracker: "0x6A7094b16dA39168f037d1cf1Ff5b41c8a8A6122",
    FeeGlpTracker: "0x9a13e4d0A098cA823f6C7a5e916ECb422241bB7a",

    StakingV1: "0x1F4997494Fcc21768a9aFB92a80e2a9d29B64782",

    StakedGmxDistributor: "0xa8260A967D4a62ac8C1740a673365bf58fEaEE47",
    StakedGlpDistributor: "0x9d48cDd04830d4440Dd996Ac5BdD238101B5F5ED",

    GmxVester: "0xd91FAd6531d9918A1f0941b719C50b44C6fA7CD6",
    GlpVester: "0x3d79969977c4c760337B8a9a71F62aFBdc4882F0",

    OrderBook: "0x9527bdfA887653d084D21c50037F6cEA2bBAEc73",
    OrderExecutor: "",
    OrderBookReader: "0x832c76b7314E5aB5FE6D0dA7568e9B34FC869997",

    PositionRouter: "0x6141f3435073fCF299DaE3B3FEC14F573985d24C",
    PositionManager: "0x1a14a82AcbfCc4b22760A36bDbf25DF00571F434",

    TraderJoeGmxAvaxPool: "0xc9b290FF37fA53272e9D71A0B13a444010aF4497",
    ReferralStorage: "0xaCca54354fd09DbB52833F76c08F94Cc6d32Dd3b",
    ReferralReader: "0x7f971577b7C2642fCbc0DaF27a1b8729fF3931c1",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}

import { MaxUint256 } from "@ethersproject/constants";
import { TransactionResponse } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import Big from "big.js";
import { BigNumber } from "ethers";
import { useTokenContract } from "hooks/useContract";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBridgeTokenAllowanceQuery } from "./queries/useBridgeTokenAllowanceQuery";

export enum BridgeApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

type Params = {
  tokenAddress: string | undefined;
  spender: string | undefined;
  isMainToken: boolean | undefined;
  tokenSymbol: string | undefined;
  bridgeName: string | undefined;
  tokenAmount: Big;
  chainId: number | undefined;
};

const AMOUNT_TO_APPROVE = MaxUint256;

function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000));
}

export function useApproveBridgeToken({
  tokenAddress,
  spender,
  isMainToken,
  tokenSymbol,
  bridgeName,
  tokenAmount,
  chainId,
}: Params): [BridgeApprovalState, () => Promise<void>] {
  const { account } = useWeb3React();
  const [isApproving, setIsApproving] = useState(false);
  const [approveTransactionSuccess, setApproveTxSuccess] = useState(false);

  const currentAllowanceQuery = useBridgeTokenAllowanceQuery(tokenAddress, account ?? undefined, spender, chainId);

  // check the current approval status
  const approvalState: BridgeApprovalState = useMemo(() => {
    if (!spender) return BridgeApprovalState.UNKNOWN;
    if (isMainToken) return BridgeApprovalState.APPROVED;
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowanceQuery?.data) return BridgeApprovalState.UNKNOWN;

    if (approveTransactionSuccess) {
      return BridgeApprovalState.APPROVED;
    }

    // amountToApprove will be defined if currentAllowance is
    return currentAllowanceQuery.data.lt(tokenAmount)
      ? isApproving
        ? BridgeApprovalState.PENDING
        : BridgeApprovalState.NOT_APPROVED
      : BridgeApprovalState.APPROVED;
  }, [approveTransactionSuccess, isMainToken, spender, currentAllowanceQuery.data, isApproving, tokenAmount]);

  useEffect(() => {
    setApproveTxSuccess(false);
  }, [tokenAddress]);

  const tokenContract = useTokenContract(tokenAddress);

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== BridgeApprovalState.NOT_APPROVED) {
      console.error("approve was called unnecessarily");
      return;
    }
    if (!tokenAddress) {
      console.error("no token");
      return;
    }

    if (!tokenContract) {
      console.error("tokenContract is null");
      return;
    }

    if (!spender) {
      console.error("no spender");
      return;
    }

    setIsApproving(true);

    const estimatedGas = await BigNumber.from(75000);

    return tokenContract
      .approve(spender, AMOUNT_TO_APPROVE.toString(), {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then(async (response: TransactionResponse) => {
        await response.wait();

        setApproveTxSuccess(true);
      })
      .catch((error: Error) => {
        console.debug("Failed to approve token", error);
        throw error;
      })
      .finally(() => {
        setIsApproving(false);
      });
  }, [approvalState, tokenAddress, tokenContract, spender]);

  return [approvalState, approve];
}

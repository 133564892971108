import ERC20_ABI from "constants/abi/erc20.json";
import Big from "big.js";
import { Web3Provider } from "@ethersproject/providers";
import { getContract } from "hooks/useContract";
import { PROVIDERS_BY_CHAIN } from "pages/Bridge/constants/chains";
import useSWR from "swr";

export function useBridgeTokenAllowanceQuery(
  tokenAddress?: string,
  owner?: string,
  spender?: string,
  chainId?: number
) {
  const allowanceQuery = useSWR(
    ["token-allowance", tokenAddress, spender, owner, chainId],
    async () => {
      const contract = tokenAddress
        ? getContract(tokenAddress, ERC20_ABI, PROVIDERS_BY_CHAIN[chainId as number] as Web3Provider)
        : undefined;

      const allowanceResponse = await contract?.allowance(owner, spender);

      if (allowanceResponse) {
        return Big(allowanceResponse.toString());
      }

      return undefined;
    }
    // { isPaused: () => Boolean(tokenAddress) && Boolean(owner) && Boolean(spender) && Boolean(chainId) }
  );

  return allowanceQuery;
}

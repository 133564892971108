import { ChainId } from "types";
import { POLY_BRIDGE } from "../bridges/poly";
import { SYNAPSE_BRIDGE } from "../bridges/synapse";
import { BridgeToken } from "./types";

export const BSC_TOKENS: Array<BridgeToken> = [
  {
    symbol: "USDC",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b89c6d3d-eb81-498b-f480-8f6741c87300/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.BSC]: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.BSC]: 18,
      [ChainId.METIS]: 6,
    },
  },
  {
    symbol: "BUSD",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/0600c8cd-6c9e-4644-0c4e-427a3992b300/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.BSC]: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.BSC]: 18,
      [ChainId.METIS]: 6,
    },
  },
  {
    symbol: "USDT",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/52913bcf-9597-4661-d045-102aff10e600/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.BSC]: "0x55d398326f99059fF775485246999027B3197955",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.BSC]: 18,
      [ChainId.METIS]: 6,
    },
  }
];

import { ChainId } from "types";
import { RELAY_BRIDGE } from "../bridges/relay";
import { SYNAPSE_BRIDGE } from "../bridges/synapse";
import { BridgeToken } from "./types";

export const FANTOM_TOKENS: Array<BridgeToken> = [
  {
    symbol: "USDC",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b89c6d3d-eb81-498b-f480-8f6741c87300/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.FANTOM]: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.FANTOM]: 6,
      [ChainId.METIS]: 6,
    },
  },
  {
    symbol: "USDT",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/52913bcf-9597-4661-d045-102aff10e600/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.FANTOM]: "0x049d68029688eAbF473097a2fC38ef61633A3C7A",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.FANTOM]: 6,
      [ChainId.METIS]: 6,
    },
  },
  {
    symbol: "WETH",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b4ea7965-b4e1-4120-34c5-e9d21aa8a600/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.FANTOM]: "0x74b23882a30290451A17c44f4F05243b6b58C76d",
      [ChainId.METIS]: "0x420000000000000000000000000000000000000A",
    },
    decimals: {
      [ChainId.FANTOM]: 18,
      [ChainId.METIS]: 18,
    },
  },
];

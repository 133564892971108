import { useWeb3React } from "@web3-react/core";
import React, { useCallback, useEffect } from "react";
import tethysToken from "img/tethys-token.svg";

import "./Header.css";
import { getAccountUrl } from "lib/legacy";
import NetworkDropdown from "../NetworkDropdown/NetworkDropdown";
import { METIS, getChainIcon, getChainName } from "config/chains";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";
import { AiOutlineMenu } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import { useTokensInfoQuery } from "hooks/queries/useTokenPriceQuery";
import { getContract } from "config/contracts";
import { formatToUSD } from "lib/numbers";
import Web3Status from "components/Web3Status";

type Props = {
  openSettings: () => void;
  small?: boolean;
  setWalletModalVisible: (visible: boolean) => void;
  disconnectAccountAndCloseSettings: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
  isDrawerVisible?: boolean;
  setIsDrawerVisible?: any;
};

const NETWORK_OPTIONS = [
  {
    label: getChainName(METIS),
    value: METIS,
    icon: getChainIcon(METIS, 24),
    color: "#E841424D",
  },
];

export function AppHeaderUser({
  openSettings,
  small,
  setWalletModalVisible,
  disconnectAccountAndCloseSettings,
  redirectPopupTimestamp,
  showRedirectModal,
  isDrawerVisible,
  setIsDrawerVisible,
}: Props) {
  const { chainId } = useChainId();
  const { active, account } = useWeb3React();

  const tokensInfo = useTokensInfoQuery();

  const tethysPrice = tokensInfo?.[getContract(chainId, "GMX").toLowerCase()]?.USDPrice ?? 0;

  useEffect(() => {
    if (active) {
      setWalletModalVisible(false);
    }
  }, [active, setWalletModalVisible]);

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId) {
        return;
      }
      return switchNetwork(option.value, active);
    },
    [chainId, active]
  );

  const selectorLabel = getChainName(chainId);

  const accountUrl = getAccountUrl(chainId, account);

  return (
    <div className="App-header-user">
      {tethysPrice && (
        <span className="App-header-user-price">
          <img src={tethysToken} className="App-header-user-price-img" alt="Tethys Price" />
          {formatToUSD(tethysPrice, 2)}
        </span>
      )}
      <div className="App-header-user-connect">
        <Web3Status
          disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
          setWalletModalVisible={() => setWalletModalVisible(true)}
        />
      </div>

      {small ? (
        <div className="App-header-user-menu" onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
          {!isDrawerVisible && <AiOutlineMenu size={15} className="App-header-user-menu-icon" />}
          {isDrawerVisible && <IoCloseOutline size={20} className="App-header-user-menu-icon" />}
        </div>
      ) : (
        <NetworkDropdown
          small={small}
          networkOptions={NETWORK_OPTIONS}
          selectorLabel={selectorLabel}
          onNetworkSelect={onNetworkSelect}
          openSettings={openSettings}
        />
      )}
    </div>
  );
}

import { ChainId } from "types";
import { SYNAPSE_BRIDGE } from "../bridges/synapse";
import { BridgeToken } from "./types";

export const ARBITRUM_TOKENS: Array<BridgeToken> = [
  {
    symbol: "USDC",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b89c6d3d-eb81-498b-f480-8f6741c87300/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.ARBITRUM]: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.ARBITRUM]: 6,
      [ChainId.METIS]: 6,
    },
  },
  {
    symbol: "USDT",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/52913bcf-9597-4661-d045-102aff10e600/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.ARBITRUM]: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.ARBITRUM]: 6,
      [ChainId.METIS]: 6,
    },
  },
  {
    symbol: "ETH",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b4ea7965-b4e1-4120-34c5-e9d21aa8a600/public",
    bridge: SYNAPSE_BRIDGE,
    isMainToken: true,
    addresses: {
      [ChainId.ARBITRUM]: "",
      [ChainId.METIS]: "0x420000000000000000000000000000000000000A",
    },
    decimals: {
      [ChainId.ARBITRUM]: 18,
      [ChainId.METIS]: 18,
    },
  },
];

import { BigNumber } from "@ethersproject/bignumber";
import { useWeb3React } from "@web3-react/core";

import Big from "big.js";
import ERC20_ABI from "constants/abi/erc20.json";
import { getContract } from "hooks/useContract";
import { PROVIDERS_BY_CHAIN } from "pages/Bridge/constants/chains";
import useSWR from "swr";

type Params = {
  tokenAddress: string | undefined;
  decimals: number | undefined;
  chainId: number | undefined;
};

export const useBridgeTokenBalanceQuery = (params: Params) => {
  const { account } = useWeb3React();

  return useSWR(
    ["get-bridge-token-balance-data", params.tokenAddress, params.chainId, account],
    async () => {
      const NetworkProvider = PROVIDERS_BY_CHAIN[params.chainId as number];

      if (params.tokenAddress) {
        const contract = getContract(params.tokenAddress, ERC20_ABI, NetworkProvider, account as string);

        const balance = (await contract?.balanceOf(account)) as BigNumber;

        return Big(balance.toString()).div(10 ** (params.decimals as number));
      } else {
        const balance = (await NetworkProvider?.getBalance(account as string)) as BigNumber;

        return Big(balance.toString()).div(10 ** (params.decimals as number));
      }
    }
    // {
    //   isPaused: () =>
    //     Boolean(account && params.decimals && params.chainId && typeof params.tokenAddress !== "undefined"),
    // }
  );
};

import styled from "styled-components";
import { BiChevronDown as DropDown } from "react-icons/bi";

export const CurrencySelectButton = styled.button<{ selected?: boolean; isTokenSelect?: boolean }>`
  width: 100%;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.text1};
  border-radius: 8px;
  background-color: unset;
  outline: none;
  padding: 4px 6px;
  cursor: pointer;
  user-select: none;
  border: none;
  transition: all 0.3s;

  &:hover {
    color: ${({ theme }) => theme.text3};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4px 0;
  `};
`;

export const Aligner = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledTokenName = styled.span<{ active?: boolean }>`
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 0;
    text-align: left;
  `};
`;

export const CurrencyLogoBox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffffff;
  color: rgba(17, 17, 17, 0.3);
  box-shadow: 0px 38px 94px rgba(0, 0, 0, 0.09), 0px 19.2375px 40.9781px rgba(0, 0, 0, 0.06075),
    0px 7.6px 15.275px rgba(0, 0, 0, 0.045), 0px 1.6625px 5.43437px rgba(0, 0, 0, 0.02925);
  flex-shrink: 0;
`;

export const CurrencyLogoBoxModal = styled(CurrencyLogoBox)`
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
  `};
`;

export const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin-left: auto;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.text3};
`;

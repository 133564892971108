import { METIS } from "./chains";

export const SUBGRAPH_URLS = {

  [METIS]: {
    stats: "https://graph-node.tethys.finance/subgraphs/name/tethysv2-stats",
    referrals: "https://graph-node.tethys.finance/subgraphs/name/tethysv2-referrals",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};

import { ChainId } from "types";
import { RELAY_BRIDGE } from "../bridges/relay";
import { SYNAPSE_BRIDGE } from "../bridges/synapse";
import { BridgeToken } from "./types";

export const AVALANCHE_TOKENS: Array<BridgeToken> = [
  {
    symbol: "USDC",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b89c6d3d-eb81-498b-f480-8f6741c87300/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.AVALANCHE]: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.AVALANCHE]: 6,
      [ChainId.METIS]: 6,
    },
  },
  {
    symbol: "WETH",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b4ea7965-b4e1-4120-34c5-e9d21aa8a600/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.AVALANCHE]: "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
      [ChainId.METIS]: "0x420000000000000000000000000000000000000A",
    },
    decimals: {
      [ChainId.AVALANCHE]: 18,
      [ChainId.METIS]: 18,
    },
  },
];

import React from "react";

import cx from "classnames";

import "./Checkbox.css";
import checkIcon from "img/check.svg";

export default function Checkbox(props) {
  const { isChecked, setIsChecked, disabled, className, boxView } = props;

  return (
    <div
      className={cx("Checkbox", { disabled, selected: isChecked }, className)}
      onClick={() => setIsChecked(!isChecked)}
    >
      <span className="Checkbox-label">{props.children}</span>

      <span className="Checkbox-icon-wrapper">
        <span className={cx("Checkbox-icon", isChecked ? "Checkbox-active" : "", boxView ? "Checkbox-icon--boxView" : "")}>
          {boxView ? (
            <span className="Checkbox-active-icon Checkbox-active-icon--boxView">
              <img src={checkIcon} className="Checkbox-icon-img" alt="Checked" />
            </span>
          ) : (
            <span className="Checkbox-active-icon"></span>
          )}
        </span>
      </span>
    </div>
  );
}

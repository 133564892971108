import React, { ReactNode, useEffect, useState } from "react";
import cx from "classnames";

import { AppHeaderUser } from "./AppHeaderUser";
import { AppHeaderLinks } from "./AppHeaderLinks";

import logoImg from "img/tethys-logo.svg";
import logoSmallImg from "img/tethys-icon.svg";
import { AnimatePresence as FramerAnimatePresence, motion } from "framer-motion";
import bgBallImg from "img/bg-moon.png";

import "./Header.css";
import { Link } from "react-router-dom";

// Fix framer-motion old React FC type (solved in react 18)
const AnimatePresence = (props: React.ComponentProps<typeof FramerAnimatePresence> & { children: ReactNode }) => (
  <FramerAnimatePresence {...props} />
);

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const slideVariants = {
  hidden: { x: "100%" },
  visible: { x: 0 },
};

const zIndexVariants = {
  open: { zIndex: 1000 },
  closed: { zIndex: 1 },
};

type Props = {
  disconnectAccountAndCloseSettings: () => void;
  openSettings: () => void;
  setWalletModalVisible: (visible: boolean) => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

export function Header({
  disconnectAccountAndCloseSettings,
  openSettings,
  setWalletModalVisible,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isNativeSelectorModalVisible, setIsNativeSelectorModalVisible] = useState(false);

  useEffect(() => {
    if (isDrawerVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isDrawerVisible]);

  return (
    <>
      {/* {isDrawerVisible && (
        <AnimatePresence>
          {isDrawerVisible && (
            <motion.div
              className="App-header-backdrop"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={fadeVariants}
              transition={{ duration: 0.2 }}
              onClick={() => setIsDrawerVisible(!isDrawerVisible)}
            ></motion.div>
          )}
        </AnimatePresence>
      )} */}
      {/* {isNativeSelectorModalVisible && (
        <AnimatePresence>
          {isNativeSelectorModalVisible && (
            <motion.div
              className="selector-backdrop"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={fadeVariants}
              transition={{ duration: 0.2 }}
              onClick={() => setIsNativeSelectorModalVisible(!isNativeSelectorModalVisible)}
            ></motion.div>
          )}
        </AnimatePresence>
      )} */}
      <header>
        <div className="App-header large">
          <div className="App-header-container-left">
            <Link className="App-header-link-main" to="/">
              <img src={logoImg} className="big" alt="Tethys Logo" />
              <img src={logoSmallImg} className="small" alt="Tethys Logo" />
            </Link>
          </div>
          <div className="App-header-container-center">
            <AppHeaderLinks
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
              setWalletModalVisible={setWalletModalVisible}
            />
          </div>
          <div className="App-header-container-right">
            <AppHeaderUser
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
              openSettings={openSettings}
              setWalletModalVisible={setWalletModalVisible}
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
            />
          </div>
        </div>
        <motion.div
          animate={isDrawerVisible ? "open" : "closed"}
          variants={zIndexVariants}
          transition={{ duration: 0.2, delay: isDrawerVisible ? 0 : 0.4 }}
          className={cx("App-header", "small", { active: isDrawerVisible })}
        >
          <div
            className={cx("App-header-link-container", "App-header-top", {
              active: isDrawerVisible,
            })}
          >
            <div className="App-header-container-left">
              <Link to="/" className="App-header-link-main clickable">
                <img src={logoImg} className="big" alt="Tethys Logo" />
                <img src={logoSmallImg} className="small" alt="Tethys Logo" />
              </Link>
            </div>
            <div className="App-header-container-right">
              <AppHeaderUser
                disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
                openSettings={openSettings}
                small
                setWalletModalVisible={setWalletModalVisible}
                redirectPopupTimestamp={redirectPopupTimestamp}
                showRedirectModal={showRedirectModal}
                setIsDrawerVisible={setIsDrawerVisible}
                isDrawerVisible={isDrawerVisible}
              />
            </div>
          </div>
        </motion.div>
        <AnimatePresence>
          {isDrawerVisible && (
            <motion.div
              onClick={() => setIsDrawerVisible(false)}
              className="App-header-links-container App-header-drawer"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={slideVariants}
              transition={{ duration: 0.2 }}
            >
              <div className="App-background"></div>
              <img className="App-background-ball" src={bgBallImg} alt="Moon" />
              <AppHeaderLinks
                small
                openSettings={openSettings}
                clickCloseIcon={() => setIsDrawerVisible(false)}
                redirectPopupTimestamp={redirectPopupTimestamp}
                showRedirectModal={showRedirectModal}
                setWalletModalVisible={setWalletModalVisible}
                disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </header>
    </>
  );
}

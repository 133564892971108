import qs from "qs";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { ChainId } from "types";

export const BRIDGE_DEFAULT_STATE = {
  tokenAddress: "0x9E32b13ce7f2E80A01932B42553652E053D6ed8e",
  chainFrom: ChainId.ETHEREUM,
  chainTo: ChainId.METIS,
};

export const useBridgeState = () => {
  const location = useLocation();

  const queryObject = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as { currency?: string; from?: string; to: string };

  const parsedQs = {
    tokenAddress: queryObject.currency ?? "",
    chainFrom: queryObject.from ? Number(queryObject.from) : undefined,
    chainTo: queryObject.to ? Number(queryObject.to) : undefined,
  };

  useEffect(() => {
    if (!(queryObject.from && queryObject.to)) {
      parsedQs.tokenAddress = BRIDGE_DEFAULT_STATE.tokenAddress;
      parsedQs.chainFrom = BRIDGE_DEFAULT_STATE.chainFrom;
      parsedQs.chainTo = BRIDGE_DEFAULT_STATE.chainTo;
    }
  }, [queryObject.from, queryObject.to]);

  return parsedQs;
};

import { SUPPORTED_BRIDGE_NETWORKS } from "pages/Bridge/constants/chains";

type Params = {
  library: any;
  account: any;
  chainId: any;
};

export const setupBridgeNetwork = async ({ library, account, chainId }: Params) => {
  try {
    await library?.send("wallet_switchEthereumChain", [{ chainId: `0x${chainId.toString(16)}` }, account]);
  } catch (switchError: any) {
    if (switchError.code === 4902) {
      try {
        await library?.send("wallet_addEthereumChain", [SUPPORTED_BRIDGE_NETWORKS[chainId], account]);
      } catch (addError) {
        console.error(`Add chain error ${addError}`);
      }
    }
    console.error(`Switch chain error ${switchError}`);
  }
};

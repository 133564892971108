import React from 'react'

export const SwapHorizontalIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.83333 2.5L2.5 5.83333L5.83333 9.16667M14.1667 17.5L17.5 14.1667L14.1667 10.8333"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M2.5 14.1667L17.5 14.1667M17.5 5.83337L2.5 5.83337L17.5 5.83337Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

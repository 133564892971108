import { StaticJsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import Big from "big.js";

import { getContract } from "hooks/useContract";

import { BridgeEstimateResult } from "pages/Bridge/constants/bridges/types";
import { PROVIDERS_BY_CHAIN, ReadOnlyProvider } from "pages/Bridge/constants/chains";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { ChainId } from "types";
import { BridgeApprovalState, useApproveBridgeToken } from "./useApproveBridgeToken";
import { useBridgeState } from "./useBridgeState";
import { useCurrentToken } from "./useCurrentToken";

type Params = {
  tokenAmount: string;
};

type Data = {
  approvalState: BridgeApprovalState;
  approve: () => Promise<void>;
  transfer: () => Promise<void>;
  bridgeEstimate: BridgeEstimateResult;
  isBridgeEstimateLoading: boolean;
};

const DEFAULT_BRIDGE_ESTIMATE = {
  amountToReceive: undefined,
  amountToReceiveSymbol: undefined,
  bridgeFee: undefined,
  bridgeFeeSymbol: undefined,
  bridgeFeeTokenAddress: undefined,
  maxSlippage: undefined,
  estimatedTransactionTime: undefined,
};

export const useManageBridge = (params: Params): Data => {
  const { account, library, chainId } = useWeb3React();
  const [bridgeEstimate, setBridgeEstimate] = useState<BridgeEstimateResult>(DEFAULT_BRIDGE_ESTIMATE);
  const [isBridgeEstimateLoading, setIsBridgeEstimateLoading] = useState(false);
  const bridgeState = useBridgeState();
  const currentToken = useCurrentToken();

  const chainFromContractInfo =
    currentToken?.bridge.contractsInfoByChains && bridgeState.chainFrom
      ? currentToken?.bridge.contractsInfoByChains[bridgeState.chainFrom]
      : undefined;

  const [approvalState, approve] = useApproveBridgeToken({
    tokenAddress: bridgeState.chainFrom ? currentToken?.addresses[bridgeState.chainFrom] : undefined,
    spender: chainFromContractInfo?.erc20HandlerAddress,
    isMainToken: currentToken?.isMainToken,
    tokenSymbol: currentToken?.symbol,
    bridgeName: currentToken?.bridge.name,
    chainId: bridgeState.chainFrom,
    tokenAmount:
      bridgeState.chainFrom && currentToken && Number(params.tokenAmount) > 0
        ? Big(params.tokenAmount).mul(10 ** currentToken?.decimals[bridgeState.chainFrom])
        : Big(0),
  });

  const isValidChain = bridgeState.chainFrom === chainId;

  const transfer = async () => {
    const currentBridgeInfo =
      currentToken?.bridge.contractsInfoByChains && bridgeState.chainFrom ? chainFromContractInfo : null;

    if (
      !currentBridgeInfo ||
      !library ||
      !account ||
      !currentToken ||
      !bridgeState.chainTo ||
      !bridgeState.chainFrom ||
      !params.tokenAmount ||
      typeof currentToken.addresses[bridgeState.chainTo] === "undefined" ||
      !isValidChain
    )
      return;

    const bridgeContract = getContract(
      currentBridgeInfo?.contract,
      currentBridgeInfo.ABI,
      library as Web3Provider,
      account
    );

    const transferTx = await currentBridgeInfo?.transfer({
      chainFrom: bridgeState.chainFrom,
      chainTo: bridgeState.chainTo,
      chainFromTokenAddress: currentToken?.addresses[bridgeState.chainFrom],
      chainToTokenAddress: currentToken?.addresses[bridgeState.chainTo],
      chainFromLocalTokenAddress: currentToken?.localAddresses
        ? currentToken?.localAddresses[bridgeState.chainFrom]
        : undefined,
      chainToLocalTokenAddress: currentToken?.localAddresses
        ? currentToken?.localAddresses[bridgeState.chainTo]
        : undefined,
      isMainToken: currentToken?.isMainToken,
      to: account,
      contract: bridgeContract,
      amountFrom: params.tokenAmount,
      amountTo: bridgeEstimate.amountToReceive,
      maxSlippage: bridgeEstimate.maxSlippage,
      bridgeFee: bridgeEstimate.bridgeFee,
      chainFromTokenDecimals: currentToken.decimals[bridgeState.chainFrom],
      chainToTokenDecimals: currentToken.decimals[bridgeState.chainTo],
      library: library,
    });

    if (!transferTx) throw new Error("No transaction");

    const CurrentReadOnlyProvider: StaticJsonRpcProvider | undefined =
      PROVIDERS_BY_CHAIN[bridgeState.chainFrom ?? ChainId.METIS];

    const depositTxReceipt = await (CurrentReadOnlyProvider ?? ReadOnlyProvider).waitForTransaction(
      transferTx.hash as string
    );

    if (depositTxReceipt && bridgeState.chainTo === ChainId.METIS && transferTx) {
      axios.get("https://chart.tethys.finance/api/sfc", {
        params: {
          cid: transferTx.hash as string,
          pid: bridgeState.chainFrom,
          k: account,
          h: currentToken?.bridge.name,
        },
      });
    }
  };

  const estimate = async () => {
    const currentBridgeInfo =
      currentToken?.bridge.contractsInfoByChains && bridgeState.chainFrom ? chainFromContractInfo : null;

    if (
      !currentBridgeInfo?.estimate ||
      !currentBridgeInfo ||
      !library ||
      !account ||
      !currentToken ||
      !bridgeState.chainTo ||
      !bridgeState.chainFrom ||
      !params.tokenAmount ||
      typeof currentToken.addresses[bridgeState.chainTo] === "undefined" ||
      !isValidChain
    ) {
      return;
    }

    setIsBridgeEstimateLoading(true);

    const bridgeContract = getContract(
      currentBridgeInfo?.contract,
      currentBridgeInfo.ABI,
      library as Web3Provider,
      account
    );

    try {
      const estimateResult = await currentBridgeInfo.estimate({
        chainFrom: bridgeState.chainFrom,
        chainTo: bridgeState.chainTo,
        chainFromTokenAddress: currentToken?.addresses[bridgeState.chainFrom],
        chainToTokenAddress: currentToken?.addresses[bridgeState.chainTo],
        amountFrom: params.tokenAmount,
        to: account,
        contract: bridgeContract,
        tokenSymbol: currentToken.symbol,
        chainFromTokenDecimals: currentToken.decimals[bridgeState.chainFrom],
        chainToTokenDecimals: currentToken.decimals[bridgeState.chainTo],
      });

      setBridgeEstimate({
        amountToReceive: estimateResult?.amountToReceive,
        amountToReceiveSymbol: estimateResult?.amountToReceiveSymbol,
        bridgeFee: estimateResult?.bridgeFee,
        bridgeFeeSymbol: estimateResult?.bridgeFeeSymbol,
        bridgeFeeTokenAddress: estimateResult?.bridgeFeeTokenAddress,
        maxSlippage: estimateResult?.maxSlippage,
        estimatedTransactionTime: estimateResult?.estimatedTransactionTime,
      });
    } finally {
      setIsBridgeEstimateLoading(false);
    }
  };

  useDebounce(
    () => {
      estimate();
    },
    300,
    [params.tokenAmount, bridgeState.chainFrom, bridgeState.chainTo, bridgeState.tokenAddress, chainId]
  );

  useEffect(() => {
    setBridgeEstimate(DEFAULT_BRIDGE_ESTIMATE);
  }, [isValidChain, bridgeState.chainFrom, bridgeState.chainTo, bridgeState.tokenAddress, chainId]);

  return {
    approvalState,
    approve,
    transfer,
    bridgeEstimate: bridgeEstimate,
    isBridgeEstimateLoading,
  };
};

import celerBridgeLogo from "../../../logos/bridges/celer.svg";
import { Bridge } from "../types";
import { CELER_ABI } from "../../abi/celerBridge/celer-abi";
import { BigNumber } from "ethers";
import { EstimateAmtRequest } from "./proto/ts-proto/gateway/gateway_pb";
import { WebClient } from "./proto/ts-proto/gateway/GatewayServiceClientPb";
import Big from "big.js";
import { capitalize } from "lodash";
import { ChainId } from "types";

const CELER_L2_CHAINS = [ChainId.METIS];

const celerEstimate = async ({ chainFrom, chainTo, tokenSymbol, to, amountFrom, chainFromTokenDecimals }) => {
  const estimateRequest = new EstimateAmtRequest();

  const parsedAmountFrom = Big(amountFrom)
    .mul(10 ** chainFromTokenDecimals)
    .toFixed();

  estimateRequest.setSrcChainId(chainFrom);
  estimateRequest.setDstChainId(chainTo);
  estimateRequest.setTokenSymbol(capitalize(tokenSymbol));
  estimateRequest.setUsrAddr(to);
  estimateRequest.setSlippageTolerance(Number(1) * 10000);
  estimateRequest.setAmt(parsedAmountFrom);
  estimateRequest.setIsPegged(false);

  const client = new WebClient("https://cbridge-prod2.celer.app", null, null);

  const res = await client.estimateAmt(estimateRequest, null);
  const responseObject = res.toObject();
  if (responseObject.err?.code) {
    return undefined;
  }

  return {
    amountToReceive: Big(responseObject.estimatedReceiveAmt),
    bridgeFee: Number(
      Big(responseObject.baseFee)
        .plus(responseObject.percFee)
        .div(10 ** 18 - chainFromTokenDecimals)
        .toFixed()
    ),
    bridgeFeeSymbol: tokenSymbol,
    maxSlippage: responseObject.maxSlippage,
    estimatedTransactionTime: [5 * 60, 20 * 60],
  };
};

const celerTransfer = async ({
  chainFrom,
  chainTo,
  chainFromTokenAddress,
  chainFromTokenDecimals,
  to,
  amountFrom,
  isMainToken,
  contract,
  maxSlippage,
}) => {
  const nonce = new Date().getTime();

  const parsedAmountFrom = Big(amountFrom)
    .mul(10 ** chainFromTokenDecimals)
    .toFixed();

  if (isMainToken) {
    const txDeposit = await contract.sendNative(
      to,
      parsedAmountFrom,
      BigNumber.from(chainTo),
      BigNumber.from(nonce),
      BigNumber.from(maxSlippage || 0),
      {
        value: parsedAmountFrom,
      }
    );

    return txDeposit;
  } else {
    const txDeposit = await contract.send(
      to,
      chainFromTokenAddress,
      parsedAmountFrom,
      BigNumber.from(chainTo),
      BigNumber.from(nonce),
      BigNumber.from(maxSlippage || 0)
    );

    return txDeposit;
  }
};

export const CELER_BRIDGE: Bridge = {
  logo: celerBridgeLogo,
  url: "https://cbridge.celer.network/#/transfer",
  name: "Celer Bridge",
  contractsInfoByChains: {
    [ChainId.METIS]: {
      ABI: CELER_ABI,
      contract: "0x841ce48F9446C8E281D3F1444cB859b4A6D0738C",
      erc20HandlerAddress: "0x841ce48F9446C8E281D3F1444cB859b4A6D0738C",
      estimate: celerEstimate,
      transfer: celerTransfer,
    },
  },
};

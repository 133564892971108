import React from "react";
import {
  CurrencySelectButton,
  Aligner,
  CurrencyLogoBox,
  StyledTokenName,
  StyledDropDown,
  CurrencyLogoBoxModal,
} from "./styles";

type Props = {
  onClick: () => void;
  label: string;
  name: string | undefined;
  logo: string | undefined;
  isTokenSelect?: boolean;
};

export const CurrencySelect: React.FC<Props> = (props) => {
  return (
    <CurrencySelectButton isTokenSelect={props.isTokenSelect} onClick={props.onClick}>
      <Aligner>
        {props.isTokenSelect ? (
          <CurrencyLogoBox>
            <img
              alt="logo"
              style={{ width: "16px", height: "16px", objectFit: "contain", borderRadius: "50%" }}
              src={props.logo}
            />
          </CurrencyLogoBox>
        ) : (
          <CurrencyLogoBox>
            <img
              alt="logo"
              style={{ width: "16px", height: "16px", objectFit: "contain", borderRadius: "50%" }}
              src={props.logo}
            />
          </CurrencyLogoBox>
        )}

        <StyledTokenName>{props.name ?? props.label}</StyledTokenName>
        <StyledDropDown selected={false} />
      </Aligner>
    </CurrencySelectButton>
  );
};
